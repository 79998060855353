import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-consginor-address-book',
  templateUrl: './consginor-address-book.component.html',
  styleUrls: ['./consginor-address-book.component.scss']
})
export class ConsginorAddressBookComponent implements OnInit {
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  filterForm: FormGroup;
  endIndex: number;
  startIndex = 1;
  OrderList=[];
  filterBy = '';
  keyword = '';
  keywordDate = '';
  dateFilter='';
  Filtertoggle:boolean=true;

  constructor(    private fb: FormBuilder) {
    this.filterForm = this.fb.group({
      OrderBy: new FormControl(""),
      StartIndex: new FormControl(""),
      PageLength: new FormControl(""),
      FilterBy: new FormControl(""),
      Keyword: new FormControl(""),
      KeywordDate: new FormControl(""),
      StartDate: new FormControl(""),
      EndDate: new FormControl(""),
      FilterScreen: new FormControl(""),
      Id: new FormControl(""),
      Delay1: new FormControl(""),
      Delay2: new FormControl(""),
      KeyWordDistance: new FormControl(""),
      CustomerId: new FormControl(""),
      FFVId: new FormControl(""),
      IsDelayClicked : new FormControl(""),
    });
   }

  ngOnInit(): void {
    this.getOrder();
  }


  getOrder()
  {
   this.OrderList=[
    {
    name:'Avinash',
    state:'Hariyana',
    cityName:'Gurgaon',
    phoneNumber:'9889787878'
  },
  {
    name:'Yogesh',
    state:'UttarPradesh',
    cityName:'Noida',
    phoneNumber:'9889787878'
  },
  {
    name:'Tomesh',
    state:'Chhattisgarh',
    cityName:'Bhilai',
    phoneNumber:'9889787878'
  },
  {
    name:'Surendra',
    state:'Rajasthan',
    cityName:'Kota',
    phoneNumber:'9889787878'
  },
  {
    name:'Raj Verdhan',
    state:'UttarPradesh',
    cityName:'Noida',
    phoneNumber:'9889787878'
  },


]
}


public handlePage(e: any, isReset = false) {
  if (!isReset) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
  }
  this.startIndex = this.currentPage * this.pageSize;
  this.endIndex = (this.currentPage * this.pageSize) + this.pageSize;
  this.getOrder();
}

  

}


