import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-preview-xpcn',
  templateUrl: './preview-xpcn.component.html',
  styleUrls: ['./preview-xpcn.component.scss']
})
export class PreviewXpcnComponent implements OnInit {

  rows = 5;
  formData: FormData = new FormData();
  addEditForm: FormGroup;
  showFreight = false;
  xpcn: any;
  invoices = [];
  waybills = [];
  invoices1 = [];
  waybills1 = [];
  invoicesCount = 0;
  waybillsCount = 0;

  freightMode;
  viaPoints;
  goodsDesc;
  packageType;
  riskType;
  cgst = 0;
  sgst = 0;
  igst = 0;
  invoicesTotal = 0;
  total = 0;
  totalBox;
  totalWt = 0;

  boxes1 = [];
  boxes2 = [];
  boxes3 = [];
  boxes4 = [];

  constructor(public dialogRef: MatDialogRef<PreviewXpcnComponent>,
    private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.addEditForm = this._fb.group({
      FileName: new FormControl(''),
      Subject: new FormControl('')
    });
  }

  ngOnInit(): void {


    if (this.data != null) {

      if (this.data['xpcn'] != null) {
        this.xpcn = this.data['xpcn'];
        this.totalBox = this.xpcn.Boxes.length;
        this.xpcn.BoxesListConsignor.forEach(x => {
          this.totalWt += x.Weight;
        });
        // const count = this.xpcn['Boxes'].filter(item => item.isSelected === true).length;
        // if (count > 0) this.totalBox = count; else this.totalBox = this.xpcn.Boxes.length;
        // console.log(this.totalBox, this.xpcn.Boxes.length)
        if (this.xpcn.ServiceType == 'LCL Aggregation')
          this.rows = 6;

        if (this.xpcn.ModeOfFreightId != null && this.xpcn.FreightModes != null
          && this.xpcn.FreightModes.length > 0 && this.xpcn.FreightModes.filter(x => x.LookupId == this.xpcn.ModeOfFreightId).length > 0) {
          this.freightMode = this.xpcn.FreightModes.filter(x => x.LookupId == this.xpcn.ModeOfFreightId)[0].LookupName;
        }
        this.showFreight = this.freightMode == 'Paid' || this.freightMode == 'To Pay' || this.xpcn['CreditFreightShow'] == 'CreditShow'; //this.xpcn['XPCNFreightDetails'] ||
        if (this.xpcn.GoodsDescId != null && this.xpcn.GoodsDescs != null
          && this.xpcn.GoodsDescs.length > 0 && this.xpcn.GoodsDescs.filter(x => x.LookupId == this.xpcn.GoodsDescId).length > 0) {
          this.goodsDesc = this.xpcn.GoodsDescs.filter(x => x.LookupId == this.xpcn.GoodsDescId)[0].LookupName;
        }
        if (this.xpcn.PackageTypeId != null && this.xpcn.PackageTypes != null
          && this.xpcn.PackageTypes.length > 0 && this.xpcn.PackageTypes.filter(x => x.LookupId == this.xpcn.PackageTypeId).length > 0) {
          this.packageType = this.xpcn.PackageTypes.filter(x => x.LookupId == this.xpcn.PackageTypeId)[0].LookupName;
        }
        if (this.xpcn.RiskTypeId != null && this.xpcn.RiskTypes != null
          && this.xpcn.RiskTypes.length > 0 && this.xpcn.RiskTypes.filter(x => x.LookupId == this.xpcn.RiskTypeId).length > 0) {
          this.riskType = this.xpcn.RiskTypes.filter(x => x.LookupId == this.xpcn.RiskTypeId)[0].LookupName;
        }
        if (this.xpcn.Via1 != null && this.xpcn.Via2 == null) {
          this.viaPoints = this.xpcn.Via1;
        }
        else if (this.xpcn.Via1 != null && this.xpcn.Via2 != null) {
          this.viaPoints = this.xpcn.Via1 + ', ' + this.xpcn.Via2;
        }

        // if (this.xpcn.Rate != null) {
        //   this.total = this.total + this.xpcn.Rate;
        // }
        // if (this.xpcn.HandlingCharges != null) {
        //   this.total = this.total + this.xpcn.HandlingCharges;
        // }
        // if (this.xpcn.HaltingCharges != null) {
        //   this.total = this.total + this.xpcn.HaltingCharges;
        // }
        // if (this.xpcn.OtherCharges != null) {
        //   this.total = this.total + this.xpcn.OtherCharges;
        // }

        // if (this.xpcn.GST != null) {
        //   this.total = this.total + this.xpcn.GST;
        //   if (this.xpcn.BillingOfficeType == 'Central') {
        //     this.igst = this.xpcn.GST;
        //   } else {
        //     this.cgst = this.xpcn.GST / 2;
        //     this.sgst = this.xpcn.GST / 2;
        //   }
        // }

        if (this.xpcn.BasicFreight != null) {
          this.total = this.total + this.xpcn.TotalAmountWithoutGST;
        }
        if (this.xpcn.HandlingCharges != null) {
          this.total = this.total + this.xpcn.HandlingCharges;
        }
        if (this.xpcn.HaltingCharges != null) {
          this.total = this.total + this.xpcn.HaltingCharges;
        }
        if (this.xpcn.OtherCharges != null) {
          this.total = this.total + this.xpcn.OtherCharges;
        }

        if (this.xpcn.GST != null) {
          this.total = this.total + (this.total * this.xpcn.GST) / 100;
          if (this.xpcn.BillingOfficeType == "Central") {
            this.igst = (this.xpcn.TotalAmountWithoutGST * this.xpcn.GST) / 100;
          }
          else {
            this.cgst = ((this.xpcn.TotalAmountWithoutGST * this.xpcn.GST) / 100) / 2;
            this.sgst = ((this.xpcn.TotalAmountWithoutGST * this.xpcn.GST) / 100) / 2;
          }
        }
      }
      if (this.data['invoices'] != null && this.data['invoices'].length > 0) {
        this.invoices = this.data['invoices'];
        this.invoices1 = this.data['invoices'];
        this.invoices.forEach(x => {
          this.invoicesTotal = this.invoicesTotal + x.InvoiceValue;
        });
        this.invoicesTotal = Number(this.invoicesTotal.toFixed(2));
        this.invoicesCount = this.invoices.length;
        if (this.invoices.length > 15) {
          this.invoices = this.invoices.slice(0, 15);
        }
      }
      if (this.data['waybills'] != null && this.data['waybills'].length > 0) {
        this.waybills = this.data['waybills'];
        this.waybills1 = this.data['waybills'];
        this.waybillsCount = this.waybills.length;
        if (this.waybills.length > 15) {
          this.waybills = this.waybills.slice(0, 15);
        }
      }
    }
  }

  close() {
    this.dialogRef.close()
  }

  downloadPDF() {
    window.open(environment.apiUrl + 'InvoiceView/DownloadLCLXPCNPDF?ids=' + this.xpcn.XPCNId + "&xpcn_code=" + this.xpcn.XPCNCode + '&isConsignor=true', "_blank");
  }

}
