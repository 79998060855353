import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { SessionGuard } from './_gaurds/session.guard';
import { OrderDashboardComponent } from './pages/orders/order-dashboard/order-dashboard.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { ServiceComponent } from './pages/service/service.component';
import { FinanceComponent } from './pages/finance/finance.component';
import { ChatComponent } from './pages/chat/chat.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { ForgetPasswordComponent } from './pages/login/forget-password/forget-password.component';
import { UtilizationComponent } from './pages/utilization/utilization.component';
import { ConsginorAddressBookComponent } from './pages/consginor-address-book/consginor-address-book.component';
import { AsnListComponent } from './pages/asn-list/asn-list.component';
import { PrintLrTrackingComponent } from './pages/print-lr-tracking/print-lr-tracking.component';
import { GenerateLrComponent } from './pages/generate-lr/generate-lr.component';
import { DeliveredComponent } from './pages/delivered/delivered.component';
import { TrackLrComponent } from './pages/track-lr/track-lr.component';
import { DownloadMisComponent } from './pages/download-mis/download-mis.component';
import { DemoVideoComponent } from './pages/demo-video/demo-video.component';
import { CancelledAsnComponent } from './pages/cancelled-asn/cancelled-asn.component';


export const routes: Routes = [
  {
    path: 'xp',
    //canActivateChild: [SessionGuard],
    component: PagesComponent,
    children: [
      // { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' }  },
      // {path: 'address-book',component:ConsginorAddressBookComponent, data: { breadcrumb: 'My Address Book' }},
      {path: 'asn-list',component:AsnListComponent, data: { breadcrumb: 'My ASN List' }},
      {path: 'print-lr-tracking',component:PrintLrTrackingComponent, data: { breadcrumb: 'Print Stickers' }},
      {path: 'track-lr',component:TrackLrComponent, data: { breadcrumb: 'Print and Track Your XPCN' }},
      {path: 'delivered',component:DeliveredComponent, data: { breadcrumb: 'Delivered' }},
      {path: 'generate-lr',component:GenerateLrComponent, data: { breadcrumb: 'Generate LR' }},
      {path: 'download-mis',component:DownloadMisComponent, data: { breadcrumb: 'Download MIS' }},
      {path: 'cancelled-asn',component:CancelledAsnComponent, data: { breadcrumb: 'Cancelled ASNs' }},
      // {path: 'finance', component:FinanceComponent, data: { breadcrumb: 'Invoices & PODs'}},
      {path: 'demo-video',component:DemoVideoComponent, data: { breadcrumb: 'Demo Video' }},


      { path: 'orders', loadChildren: () => import('./pages/orders/orders.module').then(x => x.OrdersModule) },
      { path: 'dashboard',   loadChildren: () => import('./pages/customers/customers.module').then(x => x.CustomersModule)},
      { path: 'service',loadChildren: () => import('./pages/service/service.module').then(x => x.ServiceModule)},
      { path: 'payables', loadChildren: () => import('./pages/finance/finance.module').then(x => x.FinanceModule)},
      { path: 'chat', component: ChatComponent, data: { breadcrumb: 'Messages' } },
      { path: 'notifications', component: NotificationsComponent, data: { breadcrumb: 'Notifications' } },
      { path: 'analytics', component: AnalyticsComponent, data: { breadcrumb: 'Dashboard' } },
      {path: 'utilization',component:UtilizationComponent, data: { breadcrumb: 'My Utilization' }}
    ]
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  {path:'forgetpassword', component:ForgetPasswordComponent},
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },


  { path: '**', component: NotFoundComponent }
];
export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
  //    preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
   useHash: true
});
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      relativeLinkResolution: 'legacy',
      // useHash: true
    })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
