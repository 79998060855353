export const environment = {
  production: true,
  
  //QA - Qwik supply chain Pvt Ltd - Customer ID
  customerId : 255,

  //Live - Qwik Supply Chain Pvt. Ltd. - Customer ID
  // customerId : 347,

 //apiUrl: 'http://localhost:59485/',
  // apiUrl : 'https://devapi.xpindia.in/',
  apiUrl: 'https://qaapi.xpindia.in/',
  // apiUrl: 'https://liveapi.xpindia.in/',
  // apiUrl : 'http://logapi.optionmatrix.org/',
  // googleMapsAPIKey: 'AIzaSyCVTDpqKxLeXLDJJxzv3yq3lCSDYTZrYU4',
  hereMapsAPIKey: 'Q0MdygffMLKgWDZnqVnpnGfDus419d_wIJkwTB4JqCY',
  firebase: {
      apiKey: "AIzaSyDaYRhjH59J2yG0U8aVRtdTtmeaA3mKGHk",
      authDomain: "xp-india.firebaseapp.com",
      projectId: "xp-india",
      storageBucket: "xp-india.appspot.com",
      messagingSenderId: "569152422671",
      appId: "1:569152422671:web:6f459d80e98ceb85bab47a",
      measurementId: "G-WR1EHKH2RB"
    },
    mapMarkerSVG: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>',
     
    // for DLno,aadhar & Pan verification 
    surepasstoken :"Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY1MjI3MDc4MSwianRpIjoiODY2MGNkYWItMmNjNS00NmVmLThmYTYtZDI1NzllY2RkOGE3IiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2Lmluc3RhbnR0cmFuc3BvcnRAYWFkaGFhcmFwaS5pbyIsIm5iZiI6MTY1MjI3MDc4MSwiZXhwIjoxOTY3NjMwNzgxLCJ1c2VyX2NsYWltcyI6eyJzY29wZXMiOlsicmVhZCJdfX0.qh7CMYjTCwXJnWg1vfQ7Fmuz2l-AnsF3tYk964z61Pw",

};

