import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FinanceService {
  headers: { headers: any; };

  constructor(private _http : HttpClient) { }
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  GetConsignorInvoice(data){
    return this._http.post(environment.apiUrl + 'api/get-consignor-invoices', data);
  }
  DownloadPODs(Id){
    return this._http.get(environment.apiUrl + 'api/download-invoice-pod?CustomerInvoiceId=' + Id);
  }
  GetPayablesDashboard(data){
    return this._http.post(environment.apiUrl + 'api/cstmr/get-payables-dashboard', data);
  }
  GetCustomerPayables(data){
    return this._http.post(environment.apiUrl + 'api/cstmr/get-customer-payables', data);
  }
  GetDuesOverDuesData(data){
    return this._http.post(environment.apiUrl + 'api/cstmr/get-dues-overdues-payables', data);
  }
  GetbilledOrdersByCustomer(data) {
    var respdata = this._http.post(environment.apiUrl + 'api/get-billed-orders-customer-annexure-details-hs', data);
    return respdata;
  }
  getXPCNXPLSList(orderId) {
    return this._http.get(environment.apiUrl + 'api/get-xpcn-xpls-list?orderId=' + orderId);
  }
  getFinalizeXPCN(xpcn) {
    return this._http.post(environment.apiUrl + 'api/get-finalize-xpcn', xpcn);
  }
  getFinalizeXPLS(xpls) {
    return this._http.post(environment.apiUrl + 'api/get-finalize-xpls', xpls);
  }
  public saveData(data) {
    return this._http.post(environment.apiUrl + 'api/update-case-raised-details', data);

  }
  // public Viewraise(data){
  //   debugger
  //   console.log("jerry",data)
  //   return this._http.post(environment.apiUrl + 'api/get-customer-outstanding-payment-details-mobile-post', data );
  // }
  public Viewraise(caseid) {
    console.log("case data is", caseid);
    //var ref = {"customerinvoiceid":data.customerinvoiceid,"invoiceno":data.invoicenumber}
    //var ref = {"customerinvoiceid":data.customerinvoiceid}
    return this._http.get(environment.apiUrl + 'get_billed-orders-case-info-CB?CaseID=' + caseid);
    //return this._http.get(environment.apiUrl + 'get_billed-orders-case-info-CB?CaseID='+caseid.CaseID+'&customerinvoiceid='+caseid.customerinvoiceid);
  }

}
