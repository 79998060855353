import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadExcelService } from 'src/app/services/download-excel.service';

@Component({
  selector: 'app-upload-csv-result-dialog',
  templateUrl: './upload-csv-result-dialog.component.html',
  styleUrls: ['./upload-csv-result-dialog.component.scss']
})
export class UploadCsvResultDialogComponent implements OnInit {

  excelData = [];
  errorRecords = [];
  constructor(
    public dialogRef: MatDialogRef<UploadCsvResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _downloadExcelService: DownloadExcelService,
  ) { }

  ngOnInit() {
    if (this.data) {
      if (this.data['RejectedBookings'] != null && this.data['RejectedBookings'].length > 0) {
        this.errorRecords = this.data['RejectedBookings'];
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }
  exportAsExcel() {
    this.excelData = [];
    this.errorRecords.forEach(row => {
      this.excelData.push({
        'ASN No': row.ASN_Num,
        'ASN Date': row.DeliveryDateTime,
        'Vendor': row.Vendor,
        'Name of vendor':  row.VendorName,
        'Created By': row.CreatedBy,
        'Number of packages': row.NoOfPackages,
        'Goods movement stat': row.GoodsMovementStat,
        'Means of Trans. ID': row.MeansOfTransId,
        'Vendor location':  row.VendorLocation,
        'Warehouse Number': row.WareHouseNum,
        'External Delivery ID': row.ExternalDeliveryId,
        'DC sit': row.DCSite,
        'Destination': row.Dest,
        'Combi': row.Combi,
        'TRIP NO': row.TripNO,
        'Transporter': row.Transporter,
        'Error Message' : row.ErrorMessage
      })
    });
    this._downloadExcelService.exportAsExcelFile(this.excelData, 'Book LCL Order Error Records');
  }


}
