import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from 'src/app/services/loader.service';
import * as jsPDF from 'jspdf';
import { Router } from '@angular/router';

import html2canvas from 'html2canvas';
import { OrderService } from '../../orders/order-dashboard/order.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { PagesServiceService } from '../../pages-service.service';
import { PreviewXpcnComponent } from '../../preview-xpcn/preview-xpcn.component';

@Component({
  selector: 'app-order-tracking-details',
  templateUrl: './order-tracking-details.component.html',
  styleUrls: ['./order-tracking-details.component.scss']
})
export class OrderTrackingDetailsComponent implements OnInit {
  @ViewChild('pdfContent', { static: false }) pdfContent: ElementRef;
  OrderDetail = null;
  XPCN: any;
  XPCNID: any;
  ASN_No: any;
  Redirect: boolean = true;

  ScreenName: any;
  Origin: any;
  Destination: any;
  ConsignorName: any;
  BookingDate: any;
  vc_booking_id:any;
  dec_charged_weight: any;
  vc_vendor_code:any;
  vc_dc_code:any;
  vc_material_type:any;
  ConsigneeName: any;
  NoOfPackages: any;
  form: FormGroup;
  searchKeyword = '';
  Status='-';

  ScreenRedirect;
  
  constructor(
    public dialogRef: MatDialogRef<OrderTrackingDetailsComponent>,
    public fb: FormBuilder,private _pageService: PagesServiceService,
    private router: Router, public dialog: MatDialog,
    private alertService: AlertService,
    public loader: LoaderService,
    private _OrderService: OrderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.fb.group({
      XPCN: new FormControl(''),
    });
  }
  async ngOnInit(): Promise<void> {
    if (this.data != null) {
      await this.loadData(this.data.res.Data);
    }
  }

  async loadData(data: any): Promise<void> {
    this.OrderDetail = data;
    this.OrderData();
  }
  Tooltip(item = []) {
    if (item['ScreenName'] != null) {
      this.ScreenName = item['ScreenName']
    } else {
      this.ScreenName = ''
    }
  }

  openLCLXPCNPreview(XPCNID) {
    this._pageService.getLCLXPCNDetailsForFinzalization(XPCNID).subscribe(data => {
      if (data != null && data['Data'] != null) {

        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;

        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;

        // window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); 
        // this._router.navigate([]).then(result => {  window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); });
        let dialogRef = this.dialog.open(PreviewXpcnComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }

  OrderData() {
    debugger
    this.Origin = this.OrderDetail[0]['Origin'];
    const xpcn = this.OrderDetail[0]['DBXPCNId']
    this.XPCNID = xpcn
    this.XPCN = this.OrderDetail[0]['XPCNNumber'];
    this.Destination = this.OrderDetail[0]['Destination'];
    this.ASN_No = this.OrderDetail[0]['ASN_No'];
    this.ConsignorName = this.OrderDetail[0]['ConsignorName'];
    this.ConsigneeName = this.OrderDetail[0]['ConsigneeName'];
    this.NoOfPackages = this.OrderDetail[0]['NoOfPackages'];
    this.vc_vendor_code = this.OrderDetail[0]['vc_vendor_code'];
    this.vc_dc_code = this.OrderDetail[0]['vc_dc_code'];
    this.dec_charged_weight = this.OrderDetail[0]['dec_charged_weight'];
    this.vc_material_type = this.OrderDetail[0]['vc_material_type'];
    this.BookingDate = this.OrderDetail[0]['BookingDate'];
    this.vc_booking_id = this.OrderDetail[0]['booking_id'];
    if (this.ASN_No != null) {
      this._OrderService.getScreenDetails(this.data.keyword).subscribe((data: any) => {
        this.ScreenRedirect = data.Data[0]['ScreenDetails']
        this.Status = data.Data[0]['vc_status'];
        this.Tooltip(this.ScreenRedirect)
      })
    }
  }
  close() {
    this.dialogRef.close()
  }
  generatePDF() {
    this.loader.showLoader()
    const content = this.pdfContent.nativeElement;
    const doc = new jsPDF.jsPDF();

    const elementsToExclude = content.querySelectorAll('.exclude-from-pdf');

    elementsToExclude.forEach(element => {
      element.style.display = 'none';
    });

    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight; // Declare heightLeft as a variable
      let position = 10;
      setTimeout(() => {
        this.loader.hideLoader()
      }, 400)
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save('consignment.pdf');
    });
  }

  openpage() {
    debugger;
    if (this.ScreenRedirect && this.ScreenRedirect.ScreenRedirectionLink) {
        // Extract the relative path from the full URL
        const relativePath = this.ScreenRedirect.ScreenRedirectionLink.split('/').pop();
        
        if (relativePath === 'cancelled-asn') {
            console.error("This ASN is in Cancelled ASN.");
            this.alertService.createAlert("This ASN is in Cancelled ASN Screen.", 0);
            this.dialogRef.close();

            return;

        }

        if (relativePath) { // Check if relativePath is not empty
            // Add "xp" segment to the relative path
            const newPath = `xp/${relativePath}`;
            const queryParams = { n: this.ASN_No };

            // Navigate to the new path with query parameters
            this.router.navigate([newPath], { queryParams: queryParams }).then(() => {
                // Close the page after navigation
                this.dialogRef.close();
            }).catch(err => {
                console.error("Error navigating:", err);
            });
        } else {
            console.error("Relative path not found in ScreenRedirectionLink");
        }
    } else {
        console.error("ScreenRedirect or ScreenRedirectionLink is missing");
    }
}}