<div class="NewHeader">
  <h1 mat-dialog-title><span>Map</span></h1>
</div>
<div mat-dialog-content>
  <div mat-dialog-content class="p-0 m-0 ">
    <agm-map [zoom]="13" style="height:400px;" [latitude]="lat" [longitude]="lng">
      <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
      <agm-circle [latitude]="lat" [longitude]="lng" [radius]="radius" [fillColor]="'#4fbdee'" [circleDraggable]="false"
        [editable]="false" [strokePosition]="'CENTER'" strokeWeight="2">
      </agm-circle>
    </agm-map>
  </div>
  <mat-dialog-actions align="end">
    <button class="btn btn-danger fa fa-times-circle" (click)="close()"
      style="margin-left: 10px ;margin-top:23px; background-color: #f44336 !important;">
      <span style="font-family: Arial, Helvetica, sans-serif;">
        Cancel</span></button>
  </mat-dialog-actions>