import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CryptoEncriptionService } from 'src/app/services/crypto-encryption.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient
  ) { }

  // for user authentication
  login(data) {
    return this.http.get(environment.apiUrl + 'api/login/web?payload='+CryptoEncriptionService.encryptJsonObject(data));
  }

  // to set password
  setPassword(data) {
    return this.http.post(environment.apiUrl + 'api/set-password', data);
  }

  // to send forgot password link
  forgotPassword(data) {
    return this.http.post(environment.apiUrl + 'api/forgot-password', data);
  }

  // to change forgot
  changePassword(data) {
    return this.http.post(environment.apiUrl + 'api/change-password', data);
  }

  // to update user profile api/update-customer-profile
  updateUserProfile(data) {
    return this.http.post(environment.apiUrl + 'api/update-customer-profile', data);
  }

  updateProfile(data) {
    return this.http.post(environment.apiUrl + 'api/update-consignor-profile', data);
  }

  //to get user profile details
  getuserdetails(){
    return this.http.get(environment.apiUrl + 'api/get-customer-profile');
  }

  
  GetCountsOfTabs() {
    return this.http.get(environment.apiUrl + 'api/get-counts-consignor-tabs');
  }
}
