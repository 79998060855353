<div class="">

    <div class="row">
        <div class="col-sm-12">
            <button class="btn pull-right m-2" style="background-color: #3E50B4; color:#fff" (click)="exportAsExcel()">
                Download CSV
            </button>
        </div>
    </div>

    <mat-card class="filter mb-3" style="margin-top: 10px;">
        <mat-card-content>
            <div class="row">
                <div class="col-sm-5">
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('Weekly')">
                        Weekly
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('Fortnightly')">
                        Fortnightly
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('MTD')">
                        MTD
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('YTD')">
                        YTD
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="CustomDate = !CustomDate">
                        Custom Date
                    </button>
                </div>

                <div class="row col-sm-7" *ngIf="CustomDate">
                    <div class="row col-lg-5">
                        <div class="col-sm-2 mt-2">
                            From:
                        </div>
                        <div class="col-sm-8">
                            <!-- <input type="text" class="form-control" placeholder="--Enter Registration--"> -->
                            <div class="input-icon right" mat-menu-item>
                                <!-- <i class="fa fa-calendar" [owlDateTimeTrigger]="dt1"></i> -->
                                <input class="form-control" placeholder="Select Date" class="form-control" id="db"
                                    [(ngModel)]="fromDate" (keydown)="inputNotAllowed()" [owlDateTimeTrigger]="dt1"
                                    [owlDateTime]="dt1" name="dt1" required />
                                <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                            </div>
                        </div>
                    </div>
                    <div class="row col-lg-5">
                        <div class="col-sm-2 mt-2">
                            To:
                        </div>
                        <div class="col-sm-8">
                            <div class="input-icon right" mat-menu-item>
                                <input class="form-control" placeholder="Select Date" class="form-control" id="db"
                                    [(ngModel)]="toDate" (keydown)="inputNotAllowed()" [owlDateTimeTrigger]="dt2"
                                    [owlDateTime]="dt2" name="dt2" required />
                                <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-lg-1 p-0 m-0 ">
                        <button type="button" class="add-hover btn btn-success pull-right mr-2"
                            (click)="dateFilterChanged('Dates')">
                            Search
                        </button>
                    </div>
                    <div class="col-lg-1 p-0 m-0">
                        <button type="button" class="btn filter-hover"
                            style="background-color: #37474f; color: #fff !important;" (click)="datefilterclear()">
                            Clear
                        </button>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="card card-body ">
        <div class="row mt-2">
            <div class="col">
                <div widget class="card border-0 box-shadow">
                    <div class="card-body widget-body">
                        <div class="row form-inline">
                            <!-- <div class="form-group col-sm-3">
                                <div class="col-sm-3  form-group no-padding" style="color: black;">
                                    From ASN Date:
                                </div>
                                <div class="col-sm-9 no-padding">
                                    <div class="input-icon right">
                                        <i class="fa fa-calendar" [owlDateTimeTrigger]="dt1"></i>
                                        <input type="text" [(ngModel)]="fromDate" class="form-control"
                                            style="min-width: 150px; margin-left: 10px;" placeholder="Select From Date"
                                            (keydown)="inputNotAllowed()" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1"
                                            name="dt1" />
                                        <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-sm-3">
                                <div class="col-sm-3  form-group no-padding" style="color: black;">
                                    To ASN Date:
                                </div>
                                <div class="col-sm-9 no-padding">
                                    <div class="input-icon right">
                                        <i class="fa fa-calendar" [owlDateTimeTrigger]="dt2"></i>
                                        <input type="text" [(ngModel)]="toDate" class="form-control"
                                            style="min-width: 150px; margin-left: 10px;" placeholder="Select To Date"
                                            (keydown)="inputNotAllowed()" [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2"
                                            name="dt2" />
                                        <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                                    </div>
                                </div>
                            </div> -->
                            <div class="form-group col-sm-3">
                                <div class="col-sm-3  form-group no-padding" style="color: black;">
                                    Category:
                                </div>
                                <div class="col-sm-9 no-padding">
                                    <mat-select style="width: 100%; border: 1px solid black;" multiple #select
                                        placeholder="Select Category" [(ngModel)]="filterCategory"
                                        class="form-control">
                                        <mat-option value="Footwear">Footwear</mat-option>
                                        <mat-option value="Apparels">Apparels</mat-option>
                                        <mat-option value="ajio">Ajio</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="form-group col-sm-3">
                                <div class="col-sm-3  form-group no-padding" style="color: black;">
                                    Vendors:
                                </div>
                                <div class="col-sm-9 no-padding">
                                    <mat-select style="width: 100%; border: 1px solid black;" multiple #select
                                        placeholder="Select Vendors" [(ngModel)]="filterVendors" class="form-control">
                                        <mat-option>
                                            <mat-select-search [(ngModel)]="Search"
                                                [placeholderLabel]="'--Search Vendors--'"
                                                [noEntriesFoundLabel]="'Not found'"></mat-select-search>
                                        </mat-option>

                                        <mat-option [value]="'selectAll'" (click)="selectAllItems()">All</mat-option>

                                        <!-- <mat-option value="All" [(ngModel)]="allSelected" (click)="optionClick()"
                                        [ngModelOptions]="{standalone: true}"
                                        (click)="toggleAllSelection()">All</mat-option> -->
                                        <mat-option
                                            *ngFor="let item of consignors |stringFilterBy:Search:'ConsigneeName'"
                                            (click)="onItemSelect(item.CustomerConsigneeId)" (deselect)="onItemDeselect(item.CustomerConsigneeId)"
                                            [value]="item.CustomerConsigneeId">{{item.ConsigneeName}}
                                            ({{item.ConsignorCode}})</mat-option>

                                    </mat-select>
                                </div>
                            </div>
                            <div class="form-group col-sm-3">
                                <div class="col-sm-3  form-group no-padding" style="color: black;">
                                    Pickup Status:
                                </div>
                                <div class="col-sm-9 no-padding">
                                    <mat-select style="width: 100%; border: 1px solid black;" multiple #select1
                                        placeholder="Select Pickup Status" [(ngModel)]="filterStatus"
                                        class="form-control">
                                        <!-- <mat-option value="All" [(ngModel)]="allSelected1"
                                            [ngModelOptions]="{standalone: true}"
                                            (click)="toggleAllSelection1()">All</mat-option> -->
                                        <mat-option value="1">Pickup Done</mat-option>
                                        <mat-option value="2">CN Created Pickup
                                            Pending</mat-option>
                                        <mat-option value="3">CN Not Created Pickup
                                            Pending</mat-option>
                                        <mat-option value="4">Pickup Cancelled</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="form-group col-sm-3" style=" float: right !important;">
                                <button class="btn fa fa-search"
                                    style="background-color: #3E50B4; color:#fff; float: right !important;"
                                    (click)="filter()">
                                    <span> Search</span>
                                </button>
                                <button class="btn fa fa-eraser"
                                    style="margin-left: 10px ; background-color:#778992; color: #fff; float: right !important;"
                                    (click)="clear()">
                                    <span> Clear</span></button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="col-sm-12 mt-2 p-0 ml-0 mr-0 tbl-container ">
        <div class="table-responsive tbl-fixed div1">

            <table class="table table-hover text-center table-bordered ">
                <thead class="padding:20px tbl-head">
                    <tr>
                        <th scope="col">S. No.</th>
                        <th scope="col">ASN No</th>
                        <th scope="col">ASN Date</th>
                        <th scope="col">Vendor Code</th>
                        <th scope="col">Vendor Name</th>
                        <th scope="col">Created By</th>
                        <th scope="col">Boxes</th>
                        <th scope="col">Vendor Location</th>
                        <th scope="col">Warehouse Number</th>
                        <th scope="col">External Delivery ID</th>
                        <th scope="col">Origin Zone</th>
                        <th scope="col">Origin State</th>
                        <th scope="col">DC Site Code</th>
                        <th scope="col">DC Site Location</th>
                        <th scope="col">DC Zone</th>
                        <th scope="col">Destination State</th>
                        <th scope="col">Pickup Days</th>
                        <th scope="col" style="min-width: 150px !important;">Pickup Status</th>
                        <th scope="col">Pickup Remarks</th>
                        <th scope="col">Docket Number</th>
                        <th scope="col">Docket Creation Date (By Vendor)</th>
                        <th scope="col">Actual Pickup Date (XP)</th>
                        <th scope="col">Booking Month</th>
                        <th scope="col">EDD *</th>
                        <th scope="col">TAT</th>
                        <th scope="col">Out for Delivery</th>
                        <th scope="col">Halting At DC</th>
                        <th scope="col">ADD</th>
                        <th scope="col">EDD Status</th>
                        <th scope="col">Status</th>
                        <th scope="col">POD Status</th>
                        <th scope="col">Freight Cost</th>
                        <th scope="col">Actual Weight</th>
                        <th scope="col">Chargeable Weight</th>
                        <th scope="col">RIL Business</th>
                        <th scope="col">Deps Remarks</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of OrderList">
                        <td>{{item.OrderCount}}</td>
                        <td>{{item.ASN_Num}}</td>
                        <td>{{item.BookingDate | date: "dd-MM-y"}}</td>
                        <td>{{item.VendorCode}}</td>
                        <td>{{item.VendorName}}</td>
                        <td>{{item.CreatedBy}}</td>
                        <td>{{item.NoOfBoxes}}</td>
                        <td>{{item.VendorLoc}}</td>
                        <td>{{item.WarehouseNo}}</td>
                        <td>{{item.ExtDelId}}</td>
                        <td>{{item.OriginRegion}}</td>
                        <td>{{item.OriginState}}</td>
                        <td>{{item.DcCode}}</td>
                        <td>{{item.DcLoc}}</td>
                        <td>{{item.DCZone}}</td>
                        <td class="text-center">{{item.DeliveryState}}</td>
                        <td>{{item.PickupDays}}</td>
                        <td>{{item.PickupStatus}}</td>
                        <td>{{item.PickupRemarks}}</td>
                        <td>{{item.XPCNCode}}</td>
                        <td>{{item.XPCNDate | date: "dd-MM-y"}}</td>
                        <td>{{item.PickedDate | date: "dd-MM-y"}}</td>
                        <td>{{item.BookingDate | date: "MMMM"}}</td>
                        <td>
                            <span *ngIf="item.PickupStatus == 'Pickup Done'">{{item.EDD | date: "dd-MM-y"}}</span>                            
                        </td>
                        <td>{{item.TAT}}</td>
                        <td>{{item.ADA | date: "dd-MM-y"}}</td>
                        <td>{{item.HaltingDays}}</td>
                        <td>{{item.DeliveryDate | date: "dd-MM-y"}}</td>
                        <td>{{item.EDDStatus}}</td>
                        <td>{{item.DelStatus}}</td>
                        <td>{{item.PodDocUrl}}</td>
                        <td>{{item.Freight}}</td>
                        <td>{{item.AcutalWeight}}</td>
                        <td>{{item.ChargedWeight}}</td>
                        <td>{{item.BusinessType}}</td>
                        <td>-</td>
                    </tr>
                    <!-- <tr *ngIf="OrderList.length == 0">
                        <td class="text-center" colspan="100">No Records Found</td>
                    </tr> -->
                </tbody>
            </table>
        </div>
    </div>
        <mat-paginator [length]="totalSize" [pageIndex]="currentPage" [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="handlePage($event)" showFirstLastButtons>
        </mat-paginator>
        <div class="pull-right"><span style="font-size:medium;"><a style="color: black; font-weight: bolder;">*</a> Date excludes Sunday & Public Holidays</span></div>
</div>