
<nav class="navbar app-header" [class.fixed-top]="settings.theme.navbarIsFixed"
  [class.logo-visible]="showInfoContent">
  <div class="d-flex flex-column w-100">
     <!-- <div id="info-content" class="panel-collapse collapse">
      <a class="close" data-toggle="collapse" href="#info-content" (click)="showInfoContent = !showInfoContent"
        [class.show]="showInfoContent">
        <span aria-hidden="true">&times;</span>
      </a>

      <div class="row align-items-center">
        <div class="col-md-5">
          <div class="d-flex justify-content-center align-items-center pt-md-0 pt-3 info">
            <img src="assets/img/users/user.jpg" class="rounded-circle user-img">
            <div class="d-flex flex-column pl-3">
              <h5 class="mb-0">Emilio Verdines <i class="fa fa-check-circle fs-13 text-success ml-2"></i></h5>
              <span>Project menecer</span>
              <div class="pt-3">
                <a routerLink="/profile" class="btn btn-success mr-1">Profile</a>
                <a routerLink="/profile/user-info" class="btn btn-gray">Edit</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="d-flex justify-content-start align-items-center top-navbar">

      <!-- <a class="logo" routerLink="/xp/analytics" (click)="closeSubMenus()">
              <span class="start">start</span><span class="ng">XP</span>
          </a> -->


      <!-- <a class="logo" routerLink="/xp/analytics" (click)="closeSubMenus()">
                <img src="../../assets/img/vendor/leaflet/XP_logo_trans.png" class="logo" alt="XP"
                    style="margin-left: -8px !important; width: 60px !important;">
            </a> -->



      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <a class="logo" routerLink="/xp/demo-video" (click)="closeSubMenus()">
              <img src="../../assets/img/vendor/leaflet/XP_logo_trans.png" class="logo" alt="XP"
                style="width: 60px !important; margin-left: 4px;margin-top:-2px" >
            </a>
          </div>
          <div class="flip-card-back">
            <a class="logo" routerLink="/xp/demo-video" (click)="closeSubMenus()">
              <img src="../../assets/img/vendor/leaflet/truck.png" class="logo" alt="XP"
                style=" width: 80px !important;">
            </a>
          </div>
        </div>
      </div>
      <!-- <span class="navbar-text menu-icon transition ml-3 mr-3" style="margin-top: 9px;" [ngClass]="{'open': settings.theme.showMenu}"
        (click)="settings.theme.showMenu = !settings.theme.showMenu">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </span> -->



      <div class="ml-2 mr-2 d-none d-sm-block">
        <a id="info-content-icon" data-toggle="collapse" href="#info-content"
          (click)="showInfoContent = !showInfoContent" class="d-flex" [class.show]="showInfoContent">
          <div [@showInfo]="showInfoContent" class="triangle"></div>
        </a>
      </div>

       <div class="top-rigth-icons ml-auto">
        <span class="d-none d-sm-inline-block" *ngIf="showFlagsMenu">
          <app-flags-menu></app-flags-menu>
        </span>
        <span class="hidden-xs-down">
          <app-user-menu></app-user-menu>
        </span>
        <span class="pl-2 pr-2" *ngIf="showLogoutOption">
          <i class="fa fa-sign-out" aria-hidden="true"></i>
        </span>
      </div> 


    </div>

    <div class="horizontal-menu-outer" *ngIf="settings.theme.menu == 'horizontal'">
      <app-horizontal-menu *ngIf="showHorizontalMenu" [menuItems]="menuItems"></app-horizontal-menu>
      <app-vertical-menu *ngIf="!showHorizontalMenu" [menuItems]="menuItems"></app-vertical-menu>
    </div>

  </div>
</nav>