<div class="">
    <div class="row">
        <div class="col-sm-12">
            <button class="btn fa fa-search m-2" style="background-color: #3E50B4; color:#fff"
                (click)="filterToggle = !filterToggle">
                <span>&nbsp;Date Filter</span>
            </button>

            <button type="button" class="btn btn-success pull-right fa fa-download m-2" (click)="bulkPODDownload()">
                <span> Bulk POD Download</span>
            </button>
            <button class="btn pull-right m-2" style="background-color: #3E50B4; color:#fff" (click)="exportAsExcel()">
                Download CSV
            </button>
        </div>
    </div>

    <mat-card class="filter col-lg-12 px-0 py-1" style="margin-top: 10px;" *ngIf="filterToggle">
        <mat-card-content>
            <div class="row">
                <div class="col-sm-12">
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('Today')">
                        Today
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('Week')">
                        Week
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('MTD')">
                        MTD
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('YTD')">
                        YTD
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="CustomDate = !CustomDate">
                        Custom Date
                    </button>

                    <span style="padding: 0px 0px 0px 139px;"> Date Filter: </span>
                    <mat-select placeholder="Filter by" style="width: 12%; border: 1px solid black; margin-left: 10px;"
                    onselect="reset()" placeholder="Filter by" [(ngModel)]="dateFilter"
                    class="form-control">
                    <mat-option value="pick">Picked Date</mat-option>
                    <mat-option value="del">ASN Date</mat-option>
                    </mat-select>

                    <span style="padding: 0px 5px 0px 138px;"> Category: </span>
                    <mat-select style="width: 11%;
                    border: 1px solid black;
                    margin-left: 0px;"
                    placeholder="Select Category" [(ngModel)]="filterCategory"
                    class="form-control" multiple #select>
                    <mat-option value="Footwear">Footwear</mat-option>
                    <mat-option value="Apparels">Apparels</mat-option>
                    <mat-option value="ajio">Ajio</mat-option>
                </mat-select>
                </div>
            </div>

            <div class="row col-sm-12 m-2 mb-3" *ngIf="CustomDate">
                <div class="col-lg-5 m-0 p-0">
                    <div class="col-sm-2 newMarginClass">
                        From:
                    </div>
                    <div class="col-sm-8">
                        <!-- <input type="text" class="form-control" placeholder="--Enter Registration--"> -->
                        <div class="input-icon right" mat-menu-item>
                            <!-- <i class="fa fa-calendar" [owlDateTimeTrigger]="dt1"></i> -->
                            <input class="form-control" placeholder="Select Date" class="form-control" id="db"
                                [(ngModel)]="fromDate" (keydown)="inputNotAllowed()" [owlDateTimeTrigger]="dt1"
                                [owlDateTime]="dt1" name="dt1" required />
                            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 m-0 p-0">
                    <div class="col-sm-2 newMarginClass">
                        To:
                    </div>
                    <div class="col-sm-8">
                        <!-- <input type="text" class="form-control" placeholder="--Enter Registration--"> -->
                        <div class="input-icon right" mat-menu-item>
                            <!-- <i class="fa fa-calendar" [owlDateTimeTrigger]="dt2"></i> -->
                            <input class="form-control" placeholder="Select Date" class="form-control" id="db"
                                [(ngModel)]="toDate" (keydown)="inputNotAllowed()" [owlDateTimeTrigger]="dt2"
                                [owlDateTime]="dt2" name="dt2" required />
                            <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                        </div>
                    </div>
                </div>

                <div class="col-lg-1 pt-4 m-0 ">
                    <button type="button" class="add-hover btn btn-success pull-right mr-2"
                        (click)="dateFilterChanged()">
                        Search
                    </button>
                </div>
                <div class="col-lg-1 pt-4 m-0">
                    <button type="button" class="btn filter-hover"
                        style="background-color: #37474f; color: #fff !important;" (click)="datefilterclear()">
                        Clear
                    </button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>


    <div class="card card-body mt-2">
        <div class="row mt-2">
            <div class="col">
                <div widget class="card border-0 box-shadow">
                    <div class="card-body widget-body">
                        <div class="row form-inline">
                            <div class="form-group col-xl-1 col-sm-6 col-12" style="color: black;">Filter By:</div>
                            <div class="form-group col-xl-2 col-sm-6 col-12">
                                <mat-select placeholder="Filter by" style="width: 100%; border: 1px solid black;"
                                    onselect="reset()" placeholder="Filter by" [(ngModel)]="filterBy"
                                    class="form-control">
                                    <mat-option value="any">Any</mat-option>
                                    <mat-option value="asnno">ASN No</mat-option>
                                    <mat-option value="asndate">ASN Date</mat-option>
                                    <mat-option value="bookingId">Booking Id</mat-option>
                                    <mat-option value="vendor name">Vendor Name</mat-option>
                                    <mat-option value="vendor code">Vendor Code</mat-option>
                                    <mat-option value="vendor loc">Vendor Location</mat-option>
                                    <mat-option value="xpcn">XPCN No</mat-option>
                                    <mat-option value="xpcndate">XPCN Date</mat-option>
                                    <mat-option value="dccode">DC Site Code</mat-option>
                                    <mat-option value="dcloc">DC Site Location</mat-option>
                                    <mat-option value="pdate">Picked Date</mat-option>
                                    <mat-option value="ddate">Delivered Date</mat-option>
                                </mat-select>

                            </div>
                            <div class="form-group col-sm-3"
                                *ngIf="filterBy != 'asndate' && filterBy != 'pdate' && filterBy != 'ddate' && filterBy != 'xpcndate'">
                                <div class="col-sm-3  form-group " style="color: black;">
                                    Keyword:
                                </div>
                                <div class="col-sm-9 ">
                                    <input style="width: 90%; border: 1px solid black;" type="text"
                                        [(ngModel)]="keyword" class="form-control " placeholder="Search by Keyword">
                                </div>
                            </div>
                            <div class="form-group col-sm-3"
                                *ngIf="filterBy == 'asndate' || filterBy == 'pdate' || filterBy == 'ddate' || filterBy == 'xpcndate'">
                                <div class="col-sm-3  form-group " style="color: black;">
                                    Date:
                                </div>
                                <div class="col-sm-9 ">
                                    <div class="input-icon right">
                                        <i class="fa fa-calendar" [owlDateTimeTrigger]="dt1"></i>
                                        <input type="text" [(ngModel)]="keywordDate" class="form-control"
                                            style="min-width: 150px; margin-left: 10px;" placeholder="Select Date"
                                            (keydown)="inputNotAllowed()" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1"
                                            name="dt1" />
                                        <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-sm-3">
                                <button class="btn fa fa-search" style="background-color: #3E50B4; color:#fff"
                                    (click)="filter()">
                                    <span> Search</span>
                                </button>
                                <button class="btn fa fa-eraser"
                                    style="margin-left: 10px ; background-color:#778992; color: #fff;"
                                    (click)="clear()">
                                    <span> Clear</span></button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="col-sm-12 mt-2 p-0 ml-0 mr-0 tbl-container ">
        <div class="table-responsive tbl-fixed div1">

            <table class="table table-hover text-center table-bordered ">
                <thead class="padding:20px tbl-head">
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">ASN No</th>
                        <th scope="col" style="min-width: 150px;">ASN Date</th>
                        <th scope="col">XP Booking Id</th>
                        <th scope="col">Vendor Name</th>
                        <th scope="col">Vendor Code</th>
                        <th scope="col">Vendor Location</th>
                        <th scope="col">XPCN No</th>
                        <th scope="col" style="min-width: 150px;">XPCN Generation Date</th>
                        <th scope="col">No Of Packages</th>
                        <th scope="col">DC Site Code</th>
                        <th scope="col">DC Site Location</th>
                        <th scope="col" style="min-width: 150px;">Picked Date & Time</th>
                        <th scope="col" style="min-width: 150px;">Delivered Date & Time</th>
                        <th scope="col">Delivery Days</th>
                        <th scope="col">RIL Business</th>
                        <th scope="col" style="min-width: 110px;">View POD</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of OrderList">
                        <td scope="row">{{item.OrderCount}}</td>
                        <td>{{item.ASN_Num}}</td>
                        <td>{{item.BookingDate | date: "dd-MM-y, (HH:mm)"}}</td>
                        <td>{{item.BookingIdCode}}</td>
                        <td>{{item.VendorName}}</td>
                        <td>{{item.VendorCode}}</td>
                        <td>{{item.VendorLoc}}</td>
                        <td>
                            <a style="cursor: pointer; color: #007BFF;"
                                (click)="openLCLXPCNPreview(item.XPCNId)">{{item.XPCNCode}}</a>
                        </td>
                        <td>{{item.XPCNDate | date: "dd-MM-y, (HH:mm)"}}</td>
                        <td>{{item.NoOfBoxes}}</td>
                        <td>{{item.DcCode}}</td>
                        <td>{{item.DcLoc}}</td>
                        <td>{{item.PickedDate | date: "dd-MM-y, (HH:mm)"}}</td>
                        <td>{{item.DeliveryDate | date: "dd-MM-y, (HH:mm)"}}</td>
                        <td>{{item.DeliveryDays}}</td>
                        <td>{{item.BusinessType}}</td>
                        <td *ngIf="item.PodDocUrl != null">
                            <a class="btn buttons" target="_blank" style="background-color: #009688; color:#fff"
                                [href]="'https://xpindiadocuments.s3.ap-south-1.amazonaws.com'+item.PodDocUrl">View
                                POD</a>

                        </td>
                        <td *ngIf="item.PodDocUrl == null">
                            View POD
                        </td>
                    </tr>
                    <tr *ngIf="OrderList.length == 0">
                        <td class="text-center" colspan="100">No Records Found</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <mat-paginator [length]="totalSize" [pageIndex]="currentPage" [pageSize]="pageSize"
                [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="handlePage($event)" showFirstLastButtons>
            </mat-paginator>
    </div>