import { Component, Inject, OnInit } from '@angular/core';
import { PagesServiceService } from '../pages-service.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-upload-csv-dialog',
  templateUrl: './upload-csv-dialog.component.html',
  styleUrls: ['./upload-csv-dialog.component.scss']
})
export class UploadCsvDialogComponent implements OnInit {

  formData: FormData = new FormData();
  isSubmitting = false;
  addEditForm: FormGroup;
  capacityOfVehicle = "";
  showDownloadCSV = false;
  orders = [];
  excelData = [];
  isSuccess = false;
  type = '';

  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<UploadCsvDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    public _downloadExcelService: DownloadExcelService,
    private _pageService: PagesServiceService,
  ) {

    this.addEditForm = this.fb.group({
      Type: new FormControl(''),
      Id: new FormControl(''),
      Doc: new FormControl('', Validators.compose([Validators.required]))
    });
  }

  ngOnInit() {
    if (this.data != null) {
      
      this.addEditForm.controls['Type'].setValue(this.data['type']);
      if (this.data['type'] == 'FFV Vehicles' || this.data['type'] == 'FFV Driver'
        || this.data['type'] == 'Fcl-Rates-Customer' || this.data['type'] == 'mis pocs'
        || this.data['type'] == 'XPCN Invoice Details' || this.data['type'] == 'XPCN Eway Bill Details'
        || this.data['type'] == 'Reliance Consignors Orders'
      ) {
        this.addEditForm.controls['Id'].setValue(this.data['id']);
      }
      this.type = this.addEditForm.controls['Type'].value;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  saveData() {
    
    if (this.addEditForm.valid) {
      this.showDownloadCSV = false;
      this.isSubmitting = true;
      this.formData.delete('FormData');
      this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
     if (this.addEditForm.controls['Type'].value == 'Reliance Consignors Orders') {        
        this._pageService.uploadBookOrderRILCSV(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Data']);
              } else {
                this.isSubmitting = false;
              }
            }
          }
        });
      }
    } 
    else {
      this.addEditForm.controls['Doc'].markAsTouched();
    }
  }

  handleFileInput(files: FileList) {
    this.formData.delete('123');
    if (files != null && files.length > 0) {
      const acceptedFileTypes = ['application/vnd.ms-excel', '.csv'];
      // const acceptedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      //   'application/vnd.ms-excel', 'image/gif', 'image/jpeg', 'application/pdf', 'image/x-eps',
      // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png'];
      let filename = files.item(0).name.toLowerCase();
      let fileExt = filename.slice(filename.length - 3, filename.length);

      if (acceptedFileTypes.includes(files.item(0).type) || fileExt === "csv") {
        if (files.item(0).size <= 10000000) {
          this.formData.delete('123');
          this.formData.append('123', files.item(0));
        } else {
          this.alertService.createAlert('File size must be less than 10 MB', 0);
        }
      }
      else {
        this.alertService.createAlert('Wrong File Format', 0);
        this.addEditForm.controls['Doc'].setValue(null);
        return;
      }
    }
  }

  downloadCSV() {
    this.excelData = [];
    if (this.orders.length > 0) {
      if(this.addEditForm.controls['Type'].value == 'Book New Order') {
        if (this.isSuccess) {
          this.orders.forEach(element => {
            this.excelData.push({
              'Customer Code': element['CustomerCode'],
              'PO Number': element['PONumber'],
              'Order Date': element['OrderDateStr'],
              'FFV Code': element['FFVCode'],
              'Vehicle Type': element['VehicleType'],
              'Vehicle Number': element['VehicleNumber'],
              'Driver Number': element['DriverNumber'],
              'Vendor Id': element['VendorId'],
              'Vendor Name': element['VendorName'],
              'Origin': element['Origin'],  
              'Pickup Location': element['PickupLocation'],
              'Origin State': element['OriginState'],
              'Origin Pincode': element['OriginPincode'],
              'Destination': element['Destination'],
              'Drop Location': element['DropLocation'],
              'Destination State': element['DestinationState'],
              'Destination Pincode': element['DestinationPincode'],
              'POC Name': element['POCName'],
              'POC Number': element['POCNumber'],
              'Total Weight': element['WeightStr'],
              'Total Boxes': element['BoxesStr'],
              'Invoice Number': element['InvoiceNumber'],
              'Invoice Date': element['InvoiceDateStr'],
              'Invoice Value': element['InvoiceValueStr'],
              'EWay Bill Number': element['EWBNo'] + '#',
              'Order Id': element['OrderId'],
              'XPCN/XPLS Number': element['XPCNNo']
            })
          });
        } else {
          this.orders.forEach(element => {
            this.excelData.push({
              'Customer Code': element['CustomerCode'],
              'PO Number': element['PONumber'],
              'Order Date': element['OrderDateStr'],
              'FFV Code': element['FFVCode'],
              'Vehicle Type': element['VehicleType'],
              'Vehicle Number': element['VehicleNumber'],
              'Driver Number': element['DriverNumber'],
              'Vendor Id': element['VendorId'],
              'Vendor Name': element['VendorName'],
              'Origin': element['Origin'],
              'Pickup Location': element['PickupLocation'],
              'Origin State': element['OriginState'],
              'Origin Pincode': element['OriginPincode'],
              'Destination': element['Destination'],
              'Drop Location': element['DropLocation'],
              'Destination State': element['DestinationState'],
              'Destination Pincode': element['DestinationPincode'],
              'POC Name': element['POCName'],
              'POC Number': element['POCNumber'],
              'Total Weight': element['WeightStr'],
              'Total Boxes': element['BoxesStr'],
              'Invoice Number': element['InvoiceNumber'],
              'Invoice Date': element['InvoiceDateStr'],
              'Invoice Value': element['InvoiceValueStr'],
              'EWay Bill Number': element['EWBNo'] + '#',
              'Error': element['Error']
            })
          });
        }
      } else if(this.addEditForm.controls['Type'].value == 'Backdated Orders') {
        if (this.isSuccess) {
          this.orders.forEach(element => {
            this.excelData.push({
              'Service Type': element['ServiceType'],
              'Customer Code': element['CustomerCode'],
              'Service Class': element['ServiceClass'],
              'Order Date': element['OrderDateStr'],
              'FFV Code': element['FFVCode'],
              'Vehicle Type': element['VehicleType'],
              'Vehicle Number': element['VehicleNumber'],
              'Driver Number': element['DriverNumber'],
              'Origin': element['Origin'],
              'Pickup Location': element['PickupLocation'],
              'Pickup/Via 1': element['Via1'],
              'Pickup/Via 1 Location': element['Via1Location'],
              'Pickup/Via 2': element['Via2'],
              'Pickup/Via 2 Location': element['Via2Location'],
              'Destination': element['Destination'],
              'Drop Location': element['DropLocation'],
              'POC Name': element['POCName'],
              'POC Number': element['POCNumber'],
              'Freight': element['RateStr'],
              'Vehicle Assigned Datetime': element['VehicleAssignedDateStr'],
              'Origin Placement Datetime': element['OriginPlacementDateStr'],
              'Origin Loading Datetime': element['OriginLoadingDateStr'],
              'Origin Departure Datetime': element['OriginDeptDateStr'],
              'Pickup/Via 1 Arrival Datetime': element['Via1ArrivalDateStr'],
              'Pickup/Via 1 Loading/Unloading Datetime': element['Via1LoadingDateStr'],
              'Via 1 Delivery Datetime': element['Via1DelDateStr'],
              'Pickup/Via 1 Departure Datetime': element['Via1DeptDateStr'],
              'Pickup/Via 2 Arrival Datetime': element['Via2ArrivalDateStr'],
              'Pickup/Via 2 Loading/Unloading Datetime': element['Via2LoadingDateStr'],
              'Via 2 Delivery Datetime': element['Via2DelDateStr'],
              'Pickup/Via 2 Departure Datetime': element['Via2DeptDateStr'],
              'Destination Arrival Datetime': element['DestArrivalDateStr'],
              'Destination Unloading Datetime': element['DestUnloadingDateStr'],
              'Destination Delivery Datetime': element['DestDelDateStr'],
              'Order Id': element['OrderId'],
              'XPCN/XPLS Number(s)': element['XPCNNo']
            })
          });
        } else {
          this.orders.forEach(element => {
            this.excelData.push({
              'Service Type': element['ServiceType'],
              'Customer Code': element['CustomerCode'],
              'Service Class': element['ServiceClass'],
              'Order Date': element['OrderDateStr'],
              'FFV Code': element['FFVCode'],
              'Vehicle Type': element['VehicleType'],
              'Vehicle Number': element['VehicleNumber'],
              'Driver Number': element['DriverNumber'],
              'Origin': element['Origin'],
              'Pickup Location': element['PickupLocation'],
              'Pickup/Via 1': element['Via1'],
              'Pickup/Via 1 Location': element['Via1Location'],
              'Pickup/Via 2': element['Via2'],
              'Pickup/Via 2 Location': element['Via2Location'],
              'Destination': element['Destination'],
              'Drop Location': element['DropLocation'],
              'POC Name': element['POCName'],
              'POC Number': element['POCNumber'],
              'Freight': element['RateStr'],
              'Vehicle Assigned Datetime': element['VehicleAssignedDateStr'],
              'Origin Placement Datetime': element['OriginPlacementDateStr'],
              'Origin Loading Datetime': element['OriginLoadingDateStr'],
              'Origin Departure Datetime': element['OriginDeptDateStr'],
              'Pickup/Via 1 Arrival Datetime': element['Via1ArrivalDateStr'],
              'Pickup/Via 1 Loading/Unloading Datetime': element['Via1LoadingDateStr'],
              'Via 1 Delivery Datetime': element['Via1DelDateStr'],
              'Pickup/Via 1 Departure Datetime': element['Via1DeptDateStr'],
              'Pickup/Via 2 Arrival Datetime': element['Via2ArrivalDateStr'],
              'Pickup/Via 2 Loading/Unloading Datetime': element['Via2LoadingDateStr'],
              'Via 2 Delivery Datetime': element['Via2DelDateStr'],
              'Pickup/Via 2 Departure Datetime': element['Via2DeptDateStr'],
              'Destination Arrival Datetime': element['DestArrivalDateStr'],
              'Destination Unloading Datetime': element['DestUnloadingDateStr'],
              'Destination Delivery Datetime': element['DestDelDateStr'],
              'Error': element['Error']
            })
          });
        }
      } else if(this.addEditForm.controls['Type'].value == 'Consignee') {
        // if (this.isSuccess) {
          this.orders.forEach(element => {
            this.excelData.push({
              'Consignee Name': element['ConsigneeName'],
              'Postcode': element['Postcode'],
              'GSTIN': element['GSTIN'],
              'POC': element['POC'],
              'Mobile No': element['Mobile'],
              'Email ID': element['Email'],
              'Address': element['Address'],
              'Error': element['Error']
            })
          });
        // }
      }
    }
    this._downloadExcelService.exportAsExcelFile(this.excelData, 'Error Records');
  }

}