<div class="NewHeader">
    <i class="fa fa-times" aria-hidden="true"
      style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;" (click)="close()"></i>
    <h1 mat-dialog-title>Upload CSV Result</h1>
  </div>
  <div mat-dialog-content class="d-flex flex-column justify-content-start align-items-center">
    <div>
      <b>Total Records: </b>{{data.InsertedRecords+data.RejectedRecords}}
    </div>
    <div>
      <b>Saved Records: </b>{{data.InsertedRecords}}
    </div>
    <div>
      <b>Rejected Records: </b>{{data.RejectedRecords}}
    </div>
  </div>
  <mat-dialog-actions align="end" class="mt-3">
    <button *ngIf="errorRecords != null && errorRecords.length > 0"  class="pull-right btn btn-default fa fa-download"
      (click)="exportAsExcel()" style="margin-right:10px;background-color: #286ba5; color: white;">
       Download Error Records
    </button>
    <button class="btn btn-danger fa fa-times" (click)="close()">
      <span> Close</span>
    </button>
  </mat-dialog-actions>