import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginService } from 'src/app/pages/login/login.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'app-user-profile',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  image: any;
  sessionDetails: any;
  formData: FormData = new FormData();
  addEditForm: FormGroup;
  UserDetails: any;
  CustomerId: any;
  Url:any;
  ImageName
  isSubmitting = false;
  isSubmit = false;
  constructor(public dialogRef: MatDialogRef<any>, private _datePipe: DatePipe,
    public dialog: MatDialog, private _loginService: LoginService,
    public fb: FormBuilder,public alertService:AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.addEditForm = this.fb.group({
      UserId: new FormControl(''),
      FirstName: new FormControl('', Validators.compose([Validators.required])),
      CustomerName: new FormControl('', Validators.compose([Validators.required])),
      Code: new FormControl('', Validators.compose([Validators.required])),
      Email: new FormControl('', Validators.compose([Validators.required])),
      Phone: new FormControl('', Validators.compose([Validators.required])),
      PAN: new FormControl('', Validators.compose([Validators.required])),
      Image: new FormControl(''),
      Address: new FormControl('', Validators.compose([Validators.required])),
      // DateOfBirth: new FormControl('', Validators.compose([Validators.required])),
      // DateOfJoining: new FormControl('', Validators.compose([Validators.required]))
    })
    this.sessionDetails = JSON.parse(localStorage.getItem('userSession'));

    if(this.sessionDetails != null){
      this.addEditForm.controls['Code'].setValue(this.sessionDetails['Code']);
      this.addEditForm.controls['Email'].setValue(this.sessionDetails['Email']);
      this.addEditForm.controls['Phone'].setValue(this.sessionDetails['Phone']);
    }

  }

  ngOnInit(): void {
    //this.customerDetails();
  }
  close(): void {
    this.dialogRef.close();
  }

  customerDetails(): void {
    this._loginService.getuserdetails().subscribe((res) => {
      if (res != null) {
        //console.log(res);
        this.UserDetails = res['Data'];
        this.image =res['Data']['ProfilePicUrl'];
      }
      if (this.sessionDetails != null || this.UserDetails != null) {
        this.addEditForm.controls['UserId'].setValue(this.UserDetails['CustomerId']);
        this.addEditForm.controls['FirstName'].setValue(this.UserDetails['UserName']);
        this.addEditForm.controls['CustomerName'].setValue(this.UserDetails['CustomerName']);
        this.addEditForm.controls['Code'].setValue(this.UserDetails['CustomerCode']);
        this.addEditForm.controls['Email'].setValue(this.UserDetails['Email']);
        this.addEditForm.controls['Phone'].setValue(this.UserDetails['Mobile']);
        this.addEditForm.controls['PAN'].setValue(this.UserDetails['PAN']);
        this.addEditForm.controls['Image'].setValue(this.UserDetails['ProfilePicUrl']);
        this.addEditForm.controls['Address'].setValue(this.UserDetails['Address']);
        // this.addEditForm.controls['DateOfBirth'].setValue(this._datePipe.transform(this.sessionDetails['DateOfBirth'], 'MM/dd/yyyy'));
        // this.addEditForm.controls['DateOfJoining'].setValue(this._datePipe.transform(this.sessionDetails['DateOfJoining'], 'MM/dd/yyyy'));
      }

    })
  }


  fileChange(input) {
    debugger
    const reader = new FileReader();
    if (input.files != null && input.files.length > 0) {
      const acceptedFileTypes = ['image/jpeg', 'image/png'];
      if (acceptedFileTypes.includes(input.files[0].type)) {
        if (input.files[0].size <= 10000000) {
          //console.log(input.files[0])
          this.formData.append('imagefile', input.files[0]);
          this.addEditForm.controls["Image"].setValue(input.files[0]['name']);
          //console.log((input.files[0]['name']))
        } else {
          this.alertService.createAlert('File size must be less than 10 MB', 0);
        }
      }
      
    }
    if (input.files.length) {
      const file = input.files[0];
      reader.onload = () => {
        this.image = reader.result;
        //console.log("Ganesh", this.image)
        // let fd = new FormData();
        // fd.append('image',this.image)
        // console.log("Ganesh",fd)
        //console.log('Ganesh',this.image);
        // this.addEditForm.controls["Image"].setValue(input.files[0]);
        // console.log(this.addEditForm.controls["Image"].setValue(input.files[0]))
        //this.formData.append('imagefile', this.image);
        //this.formData.append('imagefile', input.files[0]);
      }

      reader.readAsDataURL(file);
    }
  }

    onUpdate() {
      debugger
      this.isSubmitting = true;
      if (this.addEditForm.valid) {
        this.isSubmit = true;
        let data = {
          CustomerId: this.addEditForm.controls["UserId"].value,
          ProfilePicUrl:this.addEditForm.controls["Image"].value,
          UserName: this.addEditForm.controls["FirstName"].value,
          CustomerName: this.addEditForm.controls["CustomerName"].value,
          CustomerCode: this.addEditForm.controls["Code"].value,
          Email: this.addEditForm.controls["Email"].value,
          Mobile: this.addEditForm.controls["Phone"].value,
          PAN: this.addEditForm.controls["PAN"].value,
          Address: this.addEditForm.controls["Address"].value
        }
        this.formData.append('FormData', JSON.stringify(data));
        this._loginService.updateUserProfile(this.formData).subscribe((data: any) => {
          console.log(data);
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                localStorage.setItem('user', JSON.stringify(data['Data']));
                this.dialogRef.close(data['Data']);
              } else {
                this.isSubmitting = false;
              }
            }
          }
          this.dialogRef.close();
        });
      }
    }


    removeImage(): void {
      this.image = '';
    }


  public openUserDialog(id) {
    let dialogRef = this.dialog.open(ChangePasswordComponent, {
      data: id,
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(data => {
    });
  }
  onlyNumbers(event) {
    let k;
    k = event.charCode;
    return ((k > 47 && k < 58));
  }

  updateprofile(){
    let data = {
      Mobile: this.addEditForm.controls["Phone"].value,
      Email: this.addEditForm.controls["Email"].value
    }

    this._loginService.updateProfile(data).subscribe((data: any) => {

      if (data != null) {

      }
      this.dialogRef.close();
    });
  }

  omit_all_char(event) {
    var k;
    k = event.charCode;
    return ((k > 47 && k < 58) || k == 8 || k == 32)
  }


}
