import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FinanceService } from './finance.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss']
})

export class FinanceComponent implements OnInit {
  
  fromDate = null;
  toDate = null;
  filterForm: FormGroup;
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  startIndex = 1;
  InvoiceList = [];
  filterToggle: boolean = true;

  constructor(private fb: FormBuilder, public financeService: FinanceService,
     private _datePipe: DatePipe,public alertService: AlertService,) { 
    this.filterForm = this.fb.group({
      StartIndex: new FormControl(""),
      EndIndex: new FormControl(""),
      FromDate: new FormControl(""),
      ToDate: new FormControl(""),
      DateType: new FormControl(""),
      InvoiceNumber: new FormControl(""),
      KeywordDate: new FormControl("")
    });
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
  }

  ngOnInit() {
    this.dateFilterChanged('YTD');
    this.getInvoiceList();
  }

  getInvoiceList(){
    this.financeService.GetConsignorInvoice(this.filterForm.value).subscribe((data) =>{
      this.InvoiceList = data['Data'];
      if (this.InvoiceList != null && this.InvoiceList.length > 0) {
        this.totalSize = this.InvoiceList[0].TotalCount;
      }    })
  }

  public handlePage(e: any, isReset = false) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    if (this.currentPage == 0) {
      this.startIndex = 1;
    } else {
      this.startIndex = (this.currentPage * this.pageSize) + 1;
    }
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
    this.getInvoiceList();
  }

  downloadPOD(InvoiceId){
    window.open(environment.apiUrl + 'InvoiceView/DownloadPodByInvoice?CustomerInvoiceId=' 
    + InvoiceId);
  }

  dateFilterChanged(displayName) {

    if (displayName == 'Weekly') {
      this.fromDate = moment().startOf('week').toDate();
      this.toDate = moment().endOf('week').toDate();
    }
    else if (displayName == 'Fortnightly') {
      var today = new Date();
      let currentDay = parseInt(String(today.getDate()).padStart(2, '0'));
      if (currentDay > 15) {
        this.fromDate = moment().startOf('month').toDate();
        this.fromDate = this.addDays(this.fromDate, 15);
        this.toDate = moment().endOf('month').toDate();
      }
      else {
        this.fromDate = moment().startOf('month').toDate();
        //   this.toDate = this.fromDate.getDate() + 15;
        this.toDate = this.addDays(this.fromDate, 14);
      }
    }
    else if (displayName == 'MTD') {
      this.fromDate = moment().startOf('month').toDate();
      this.toDate = moment().endOf('month').toDate();
    }
    else if (displayName == 'QTD') {
      this.fromDate = moment().startOf('quarter').toDate();
      this.toDate = moment().endOf('quarter').toDate();
    }
    else if (displayName == 'YTD') {
      let temp = new Date().getMonth() + 1;
      if (temp > 3) {
        let year1 = new Date().getFullYear();
        let year2 = year1 + 1;
        let aprildate = "04" + "-" + "01" + "-" + year1;
        let marchdate = "03" + "-" + "31" + "-" + year2;
        this.fromDate = new Date(aprildate);
        this.toDate = new Date(marchdate);
      }
      else {
        let year1 = new Date().getFullYear();
        let year2 = year1 - 1;
        let aprildate = "04" + "-" + "01" + "-" + year2;
        let marchdate = "03" + "-" + "31" + "-" + year1;
        this.fromDate = new Date(aprildate);
        this.toDate = new Date(marchdate);

      }
    }
    else if (displayName == 'Dates') {
      if (new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')) > new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'))) {
        this.alertService.createAlert('From date cannot be grater than to date', 0);
      } else {
        this.filterForm.controls["FromDate"].setValue(new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')));
        this.filterForm.controls["ToDate"].setValue(new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd')));
      }
    }
    this.filterForm.controls["FromDate"].setValue(new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')));
    this.filterForm.controls["ToDate"].setValue(new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd')));
    this.getInvoiceList();

  }
  addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  filter(){
    this.filterForm.controls["KeywordDate"].setValue(this._datePipe.transform(this.filterForm.controls["KeywordDate"].value, 'yyy-MM-dd'));
    this.getInvoiceList();
  }
  clear(){
    this.filterForm.reset();
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
    this.dateFilterChanged('YTD');
  }
}
