import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PagesServiceService } from '../pages-service.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { MatDialog } from '@angular/material/dialog';
import { UploadCsvDialogComponent } from '../upload-csv-dialog/upload-csv-dialog.component';
import { UploadCsvResultDialogComponent } from '../upload-csv-result-dialog/upload-csv-result-dialog.component';
import { MenuService } from 'src/app/theme/components/menu/menu.service';
import { LoginService } from '../login/login.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-asn-list',
  templateUrl: './asn-list.component.html',
  styleUrls: ['./asn-list.component.scss'],
  providers:[MenuService]
})
export class AsnListComponent implements OnInit {
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  xpcnDetails: any[] = []
  isXPCNAdded: boolean = false
  canXPCNAdded: boolean = true
  filterForm: FormGroup;
  endIndex: number;
  startIndex = 1;
  OrderList = [];
  filterBy = '';
  asn_no = '';
  keyword = '';
  keywordDate = '';
  dateFilter = '';
  user;

  filterToggle: boolean = true;
  CustomDate: boolean = false;

  public popoverStatusTitle: string = "Confirm Generate LR Number";
  public popoverStatusMessage: string =
    "Are you sure you want to Generate a new LR Number?";
  constructor(private fb: FormBuilder,
    private _pageService: PagesServiceService,
    private _datePipe: DatePipe,
    public _activeRoute: ActivatedRoute,
    private dialog: MatDialog,
    public alertService: AlertService,
    public _downloadExcelService: DownloadExcelService,
    private _menuservice :MenuService,
    private _loginService :LoginService
    ) {
      if(this._activeRoute.snapshot.queryParams['n'] != null) {
        this.asn_no = this._activeRoute.snapshot.queryParams['n'];
        console.log(this.asn_no,'asn_list')
       }
    this.user = JSON.parse(localStorage.getItem('userSession'));
    this.filterForm = this.fb.group({
      OrderBy: new FormControl(""),
      StartIndex: new FormControl(""),
      EndIndex: new FormControl(""),
      FilterBy: new FormControl(""),
      Keyword: new FormControl(""),
      KeywordDate: new FormControl(""),
      OrderByDirection: new FormControl(""),
      FromDate: new FormControl(""),
      ToDate: new FormControl(""),
      DateType: new FormControl("")
    });
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
  }

  ngOnInit() {
    this.filterForm.controls["DateType"].setValue('YTD');

    if (this.asn_no != null || this.asn_no != '') {
      this.filterForm.controls['FilterBy'].setValue('asnno');
      this.filterForm.controls['Keyword'].setValue(this.asn_no);
    }else{
      this.filterForm.controls['FilterBy'].setValue(null);
      this.filterForm.controls['Keyword'].setValue(null);
    }
    this.getCounts()
    this.getASnList();

  }

  tabscount: any
  getCounts() {
    this._loginService.GetCountsOfTabs().subscribe(data => {
      if (data != null) {
        this.tabscount = data['Data'];

        if (this.tabscount.length > 0) {
          localStorage.setItem('tabscount', JSON.stringify(data['Data']));

          this._menuservice.getVerticalMenuItems()
        }
      }
    })
  }

  getASnList() {
    this._pageService.GetAsnList(this.filterForm.value).subscribe((res) => {
      // console.log(res["Data"]);
      this.isXPCNAdded = false;
      this.OrderList = res["Data"];
      if (this.OrderList != null && this.OrderList.length > 0) {
        this.totalSize = this.OrderList[0].TotalCount;
      }
      this.OrderList.forEach(x => {
        if (x['XPCNId'] != null) {
          x.EncryptedOrderId = btoa(x['OrderId'].toString());
          x.EncryptedXPCNId = btoa(x['XPCNId'].toString());
          x.EncryptedASN = btoa(x['ASN_Num'].toString());
        }
      });
    });
  }

  generateLr(bookingId, boxes) {
    // debugger;
    this.isXPCNAdded = true
    if (bookingId != null) {
      this._pageService.generateLRPickUpNumber(bookingId, boxes).subscribe(res => {
        if (res['Success']) {
          this.getASnList();
          // this.isXPCNAdded = true
          this.xpcnDetails.push(res['Data'])
          // this.checkForAddingXPCN(true)
          // this.alertService.createAlert(res['Message'], 1)
        }
        else {
          // this.alertService.createAlert(res['Message'], 0)
        }
      })
    }
    else {
      // this.alertService.createAlert("Something went wrong", 0)
    }
  }

  filter() {
    if (this.filterBy != '' && this.filterBy != null) {
      this.filterForm.controls['FilterBy'].setValue(this.filterBy);
      if (this.filterBy != 'asndate' && this.filterBy != 'update') {
        if (this.keyword.trim() != '' && this.keyword != null) {
          this.filterForm.controls['Keyword'].setValue(this.keyword.trim());
        } else {
          this.alertService.createAlert("Please enter keyword", 0);
          return;
        }
        this.keywordDate = null;
      } else {
        this.keyword = null;
        if (this.keywordDate != '' && this.keywordDate != null) {
          this.filterForm.controls['KeywordDate'].setValue(this._datePipe.transform(this.keywordDate, 'yyyy-MM-dd'));
        } else {
          this.alertService.createAlert("Please select date", 0);
          return;
        }
      }
      this.getASnList();
    } else {
      this.alertService.createAlert("Please select filter by", 0);
      return;
    }
  }

  clear() {
    this.startIndex = 1;
    this.pageSize = 5;
    this.currentPage = 0;
    this.totalSize = 0;
    this.filterBy = '';
    this.keyword = null;
    this.keywordDate = null;
    this.filterForm.controls['FilterBy'].setValue(null);
    this.filterForm.controls['Keyword'].setValue(null);
    this.filterForm.controls['OrderBy'].setValue(null);
    this.filterForm.controls['OrderByDirection'].setValue(null);
    this.filterForm.controls['KeywordDate'].setValue(null);
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["EndIndex"].setValue(this.pageSize);
    this.getASnList();
  }

  public handlePage(e: any, isReset = false) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    if (this.currentPage == 0) {
      this.startIndex = 1;
    } else {
      this.startIndex = (this.currentPage * this.pageSize) + 1;
    }
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
    this.getASnList();
  }

  fromDate
  toDate

  dateFilterChanged(type) {

    this.filterForm.controls["DateType"].setValue(null);

    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    this.dateFilter = type;
    this.filterForm.controls["DateType"].setValue(this.dateFilter);

    if (type == 'Dates') {
      if (new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')) > new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'))) {
        this.alertService.createAlert('From date cannot be grater than to date', 0);
      } else {
        this.filterForm.controls["FromDate"].setValue(new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')));
        this.filterForm.controls["ToDate"].setValue(new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd')));
      }
    } else {
      this.filterForm.controls["FromDate"].setValue(null);
      this.filterForm.controls["ToDate"].setValue(null);

      this.fromDate = null;
      this.toDate = null;
    }

    this.getASnList();

  }

  datefilterclear() {
    this.fromDate = null;
    this.toDate = null;
    this.filterForm.controls["DateType"].setValue(null);
    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    this.getASnList();
  }

  excelData: any[];

  public exportAsExcel() {
    this.excelData = [];
    let list = [];

    this.filterForm.controls['EndIndex'].setValue(this.totalSize);
    this._pageService.GetAsnList(this.filterForm.value).subscribe(data => {
      if (data != null) {
        list = data['Data'];
        if (list.length > 0) {
          list.forEach(element => {
            this.excelData.push({
              'ASN No': element['ASN_Num'],
              'ASN Date': this._datePipe.transform(element['BookingDate'], 'dd-MM-y, (HH:mm)'),
              'XP Booking Id': element['BookingIdCode'],
              'Vendor Code': element['VendorCode'],
              'Vendor Name': element['VendorName'],
              'Vendor Location': element['VendorLoc'],
              'No Of Packages': element['NoOfBoxes'],
              'DC Site Code': element['DcCode'],
              'DC Site Location': element['DcLoc'],
              'Upload Date': this._datePipe.transform(element['CreatedDate'], 'dd-MM-y, (HH:mm)'),
              'RIL Business': element['BusinessType']
            })
          });
        }
        else {
          this.excelData.push({
            'ASN No': '-',
            'ASN Date': '-',
            'XP Booking Id': '-',
            'Vendor Code': '-',
            'Vendor Name': '-',
            'Vendor Location': '-',
            'No Of Packages': '-',
            'DC Site Code': '-',
            'DC Site Location': '-',
            'Upload Date': '-'
          })
        }

        this._downloadExcelService.exportAsExcelFile(this.excelData, 'ASN List');
      }
    })
  }

  downloadSampleExcel() {
    this.excelData = [];
    this.excelData.push({
      'ASN No': '195055013',
      'ASN Date': 'mm/dd/yyyy',
      'Vendor Code': '30403391',
      'Name of vendor': 'MALIK TANNING INDUSTRIES',
      'Created by': 'Pranay Voore',
      'Number of packages': '10',
      'Goods movement stat.': '-',
      'Means of Trans. ID': '-',
      'Vendor location': 'NOIDA',
      'Warehouse Number': '379',
      'External Delivery ID': 'MTI/251/23-24',
      'DC sit': 'V050',
      'Dest.': 'Sultanpur',
      'Combi': '30403391V050',
      'TRIP NO': 'Regular Mode',
      'Transporter': 'XP INDIA'
    });
    this._downloadExcelService.exportAsExcelFile(this.excelData, 'Sample CSV')
  }


  public openUploadCSV() {
    // this.dataUpdated = false; 
    let dialogRef = this.dialog.open(UploadCsvDialogComponent, {
      // SENDNG THE CUSTOMER ID HERE BY DEFAULT FOR RELIANCE CUSTOMER - Yathish 
      data: { type: 'Reliance Consignors Orders', id: environment.customerId },
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data != null) {
        let dialogRef = this.dialog.open(UploadCsvResultDialogComponent, {
          data: data,
          height: 'auto',
          width: '600px',
          autoFocus: false
        });
        dialogRef.afterClosed().subscribe(data => {
          this.getASnList();
        });
      }
    });
  }
}

