import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  headers: { headers: any; };
  constructor(private _http: HttpClient) { }
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }
  GetCountTabs() {
    return this._http.get(environment.apiUrl + 'api/cstmr/get-count-above-tabs');
  }
  GetPlacementOrderByDistanceNumbers() {
    return this._http.get(environment.apiUrl + 'api/cstmr/get-orders-for-placement-by-distance');
  }
  GetPlacementETPCount(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-count-for-etp',data);
  }
  getOrdersPlacementTracking(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/services/trip/get-orders-placemet-tracking-web',data);
  }
  GetPlacementGraphCustomer(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-graph-cutomer-placement-web',data);
  }
  GetPlacementGraphDelay(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-graph-delay-placement-web',data);
  }
  getTripsFlowWaitingLoad(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-orders-for-waiting-for-loading-web',data);
  }
  GetCustomerForLoadnigGraph(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-graph-customer-for-loading-web',data);
  }
  GetWaitingForLoadnigGraph(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-graph-waiting-hours-for-loading-web',data);
  }
  GetCustomerOrderByDistanceNumbers() {
    return this._http.get(environment.apiUrl + 'api/cstmr/get-numbers-for-order-by-distance-web');
  }
  GetIntransitGraphETACount(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-count-for-eta-web',data);
  }

  GetIntransitGraphCustomer(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-graph-cutomer-intranist-web',data);
  }
  GetIntransitGraphDelay(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-graph-delay-intranist-web',data);
  }

  getTripsFlow(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/services/trip/get-services-trips-flow-web',data);
  }
  GetOrderByDistanceHaltedNumbers() {
    return this._http.get(environment.apiUrl + 'api/cstmr/get-orders-for-halted-by-distance-web');
  }

  GetHaltedGraphCustomer(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-graph-customer-for-halted-web',data);
  }
  GetHaltingHaltedHoursGraphDelay(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-graph-waiting-hours-for-halted-web',data);
  }
  getOrdersHaltedTrips(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/services/trip/get-services-trips-halted-web',data);
  }
  GetArrivedGraphCustomer() {
    return this._http.get(environment.apiUrl + 'api/cstmr/get-graph-cutomer-arrived-web');
  }

  getTripsFlowArrived(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/services/trip/get-services-arrived-web',data);
  }
  GetArrivedGraphUnloading(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-graph-halting-hours-web',data);
  }
  GetPerformanceOrderByDistanceNumbers(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-orders-for-performance-by-distance-web',data);
  }

  GetEntitiesForPerformanceTrips(filter) {
    return this._http.post(environment.apiUrl + 'api/cstmr/services/get-entities-performance-trip-web',filter);
  }
  GetZoneTrips(data){
    return this._http.post(environment.apiUrl + 'api/cstmr/services/get-zone-trips-web',data);
  }
  GetTripsCases(tripId) {
    return this._http.get(environment.apiUrl + 'api/services/trip/get-trips-cases/' + tripId);
  }
  GetTripsCasesCLosed(tripId) {
    return this._http.get(environment.apiUrl + 'api/services/trip/get-trips-cases-closed/' + tripId);
  }
}
