import { Component, OnInit } from '@angular/core';
import { DemoVideoLanguageComponent } from '../demo-video-language/demo-video-language.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-demo-video',
  templateUrl: './demo-video.component.html',
  styleUrls: ['./demo-video.component.scss']
})
export class DemoVideoComponent implements OnInit {

  isEnglish = null;
  constructor(
    private dialog: MatDialog,
    ) { }

  ngOnInit(): void {
    this.openPopup();
  }

  openPopup() {
    // this.dataUpdated = false; 
    let dialogRef = this.dialog.open(DemoVideoLanguageComponent, {
      // SENDNG THE CUSTOMER ID HERE BY DEFAULT FOR RELIANCE CUSTOMER - Yathish 
      data: null,
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data != null) {
        this.isEnglish = data;
      }
    });
  }
  
}
