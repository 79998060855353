<div class="dialog-container">
    <button class="btn btn-danger fa fa-times-circle close-icon" (click)="closewindow()">
        <span style="font-family: Arial, Helvetica, sans-serif;">
            Close</span></button>
    <div class="NewHeader">
        <!-- <i class="fa fa-times" aria-hidden="true"
        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;" (click)="close(true)"></i> -->
        <h1 mat-dialog-title>Select Language</h1>
    </div>
    <div mat-dialog-content>
        <table width="100%">
            <tr>
                <td width="50%" style="text-align: center !important;" title="Hindi Language Coming Soon..">
                    <button style="width: 90%;" class="btn btn-primary" (click)="close(false)">
                        Hindi
                    </button>
                </td>
                <td width="50%" style="text-align: center !important;">
                    <button style="width: 90%;" class="btn btn-primary" (click)="close(true)">
                        English
                    </button>
                </td>
            </tr>
        </table>
    </div>
</div>