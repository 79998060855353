import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  headers: { headers: any; };
  constructor(private _http: HttpClient) { }
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }
  getOrdersForDashboard(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-customer-orders-for-dashboard-web', data);
  }
  getOrdersCountForDashboard(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-cstmr_orders-count-for-dashboard-web', data);
  }
  getOrdersForDashboard2(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-customer_orders-for-dashboard-stats-web', data);
  }

  getOrdersFreightCountForDashboard(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-orders-freight-count-for-dashboard-web', data);
  }

  GetRescheduledOrders() {
    return this._http.get(environment.apiUrl + "api/cstmr/get-rescheduled-orders");
  }

  GetRescheduledOrdersHistory(OrderId){
    return this._http.get(
      environment.apiUrl + "api/get-rescheduled-orders-history/" + OrderId
    );

  }

  GetRescheduledOrdersReasons() {
    return this._http.get(environment.apiUrl + "api/get-rescheduled-reasons");
  }

  InsertRescheduledOrderReason(data) {
    return this._http.post(environment.apiUrl + "api/cstmr/insert-order-rescheduled-by-Customer",data
    );
  }
  GetCancelledOrders() {
    return this._http.get(environment.apiUrl + "api/cstmr/get-cancelled-orders");
  }
  GetCancelOrderReasons() {
    return this._http.get(environment.apiUrl + "api/get-cancelled-reasons");
  }
  InsertCancelOrderReason(data){return this._http.post(
    environment.apiUrl + "api/cancel-customer-order",
    data
  );

  }

  sendEmailsWithCSV(data) {
    return this._http.post(environment.apiUrl + 'api/send-emails-csv', data);
  }

  getOrderOptions() {
    return this._http.get(environment.apiUrl + 'api/get-order-options');
  }

  LCLOrderDetails(searchKeyword: string) {
    return this._http.get(`${environment.apiUrl}api/get-data-for-lcl-track-order_for_consignor?searchKeyword=${searchKeyword}`);
  }

  getScreenDetails(data){
    return this._http.get(environment.apiUrl + `api/getscreenDetail_consignor?vc_keyword=${data}`)
  }
}
