<div class="">
    <mat-card class="filter col-lg-9 px-0 py-1" style="margin-top: 10px;" *ngIf="filterToggle">
        <mat-card-content>
            <div class="row mt-5">
                <div class="col-sm-12">
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('Weekly')">
                        Weekly
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('Fortnightly')">
                        Fortnightly
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('MTD')">
                        MTD
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('QTD')">
                        QTD
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('YTD')">
                        YTD
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="CustomDate = !CustomDate">
                        Custom Date
                    </button>
                </div>
            </div>

            <div class="row col-sm-12 m-2 mb-3" *ngIf="CustomDate">
                <div class="col-lg-5 m-0 p-0">
                    <div class="col-sm-2 newMarginClass">
                        From:
                    </div>
                    <div class="col-sm-8">
                        <div class="input-icon right" mat-menu-item>
                            <input class="form-control" placeholder="Select Date" class="form-control" id="db"
                                [(ngModel)]="fromDate" (keydown)="inputNotAllowed()" [owlDateTimeTrigger]="dt1"
                                [owlDateTime]="dt1" name="dt1" required />
                            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 m-0 p-0">
                    <div class="col-sm-2 newMarginClass">
                        To:
                    </div>
                    <div class="col-sm-8">
                        <div class="input-icon right" mat-menu-item>
                            <input class="form-control" placeholder="Select Date" class="form-control" id="db"
                                [(ngModel)]="toDate" (keydown)="inputNotAllowed()" [owlDateTimeTrigger]="dt2"
                                [owlDateTime]="dt2" name="dt2" required />
                            <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                        </div>
                    </div>
                </div>

                <div class="col-lg-1 p-0 m-0 ">
                    <button type="button" class="add-hover btn btn-success pull-right mr-2"
                        (click)="dateFilterChanged('Dates')">
                        Search
                    </button>
                </div>
                <div class="col-lg-1 p-0 m-0">
                    <button type="button" class="btn filter-hover"
                        style="background-color: #37474f; color: #fff !important;" (click)="datefilterclear()">
                        Clear
                    </button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="card card-body mt-2">
        <div class="row mt-2">
            <div class="col">
                <div widget class="card border-0 box-shadow">
                    <div class="card-body widget-body">
                        <form [formGroup]="filterForm">
                            <div class="row form-inline">
                                <div class="form-group col-sm-3">
                                    <div class="col-sm-3  form-group " style="color: black;">
                                        Invoice Number:
                                    </div>
                                    <div class="col-sm-9 ">
                                        <input style="width: 90%; border: 1px solid black;" type="text"
                                            formControlName="InvoiceNumber" class="form-control " placeholder="Search by Invoice Number">
                                    </div>
                                </div>
                                <div class="form-group col-sm-3">
                                    <div class="col-sm-3  form-group " style="color: black;">
                                        Due Date:
                                    </div>
                                    <div class="col-sm-9 ">
                                        <div class="input-icon right">
                                            <i class="fa fa-calendar" [owlDateTimeTrigger]="dt1"></i>
                                            <input type="text" formControlName="KeywordDate" class="form-control"
                                                style="min-width: 150px; margin-left: 10px;" placeholder="Select Date"
                                                 [owlDateTimeTrigger]="dt1"
                                                [owlDateTime]="dt1" name="dt1" />
                                            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-sm-3">
                                    <button class="btn fa fa-search" style="background-color: #3E50B4; color:#fff"
                                        (click)="filter()">
                                        <span> Search</span>
                                    </button>
                                    <button class="btn fa fa-eraser"
                                        style="margin-left: 10px ; background-color:#778992; color: #fff;"
                                        (click)="clear()">
                                        <span> Clear</span></button>
                                </div>
                            </div>
                        </form>

                    </div>

                </div>


            </div>

        </div>

    </div>



    <div class="col-sm-12 mt-2 p-0 ml-0 mr-0 tbl-container ">
        <div class="table-responsive tbl-fixed div1">
            <table class="table table-hover text-center table-bordered ">
                <thead class="padding:20px tbl-head">
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Invoice No.</th>
                        <th scope="col">Invoice Date</th>
                        <th scope="col">Freight</th>
                        <th scope="col">Billed Amount</th>
                        <th scope="col">No Of Orders</th>
                        <th scope="col">Due Date</th>
                        <th scope="col">Download PODs</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of InvoiceList; let i=index">
                        <td scope="row">{{i+1}}</td>
                        <td>{{item.InvoiceNumber}}</td>
                        <td>{{item.InvoiceDate | date: "dd-MM-y"}}</td>
                        <td>{{item.Freight}}</td>
                        <td>{{item.GrossAmount}}</td>
                        <td>{{item.OrderCount}}</td>
                        <td>{{item.DueDate | date: "dd-MM-y"}}</td>
                        <td><i class="fa fa-download" style="color: rgb(0, 0, 0); cursor: pointer;"
                                (click)="downloadPOD(item.InvoiceId)"></i></td>
                    </tr>
                    <tr *ngIf="InvoiceList.length == 0">
                        <td class="text-center" colspan="100">No Records Found</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>


    <mat-paginator [length]="totalSize" [pageIndex]="currentPage" [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="handlePage($event)" showFirstLastButtons>
    </mat-paginator>


</div>