<div class="NewHeader">
    <h1 style="  background-color: #8392e1;
    font-size: 32px;
    height: 50px;
    text-align: center;">
        Track Your Consignment ({{ ASN_No }})
    </h1>
</div>
<div mat-dialog-content style="margin: 0px !important; padding: 0px !important; overflow-x: hidden">
    <div class="col-sm-12" style="padding: 0px" #pdfContent>
        <table border="1" style="width: 100%" class="table table-bordered">
            <tr>
                <th class="pl-2" style="width: 16.66%">
                    <label style="font-size: 13px">ASN NO / XPCN </label>
                    <div class="subtitle">
                        <span class="inner-screen" style="font-size: 14px">{{ASN_No}}
                        </span>/  <span class="inner-screen" style="font-size: 14px"style="cursor: pointer; color: #007BFF;"
                        (click)="openLCLXPCNPreview(XPCNID)" >{{XPCN}}
                        </span>
                    </div>
                </th>
                <th class="pl-2" style="width: 16.66%">
                    <label style="font-size: 13px">Booking ID </label>
                    <div class="subtitle">
                        <span class="inner-screen" style="font-size: 14px">{{vc_booking_id}}
                        </span>
                    </div>
                </th>
                <th class="pl-2" style="width: 16.66%">
                    <label style="font-size: 13px">ASN Date</label>
                    <div class="subtitle">
                        <span class="inner-screen" style="font-size: 14px">{{ BookingDate | date : "dd-MM-y" }}
                        </span>
                    </div>
                </th>
                <th class="pl-2" style="width: 16.66%">
                    <label style="font-size: 13px">Vendor Code</label>
                    <div class="subtitle">
                        <span class="inner-screen" style="font-size: 14px">{{vc_vendor_code}}
                        </span>
                    </div>
                </th>
                <th class="pl-2" style="width: 16.66%">
                    <label style="font-size: 13px">DC Site Code</label>
                    <div class="subtitle">
                        <span class="inner-screen" style="font-size: 14px">{{vc_dc_code}}
                        </span>
                    </div>
                </th>
                

            </tr>
            <tr>
                <th class="pl-2" style="width: 16.66%">
                    <label style="font-size: 13px">Consignor Name </label>
                    <div class="subtitle">
                        <span class="inner-screen" style="font-size: 14px">{{ConsignorName}}
                        </span>
                    </div>
                </th>
                <th class="pl-2" style="width: 16.66%">
                    <label style="font-size: 13px">Consignee Name </label>
                    <div class="subtitle">
                        <span class="inner-screen" style="font-size: 14px">{{ConsigneeName}}
                        </span>
                    </div>
                </th>
                <th class="pl-2" style="width: 16.66%">
                    <label style="font-size: 13px">From </label>
                    <div class="subtitle">
                        <span class="inner-screen" style="font-size: 14px">{{Origin}}
                        </span>
                    </div>
                </th>
                <th class="pl-2" style="width: 16.66%">
                    <label style="font-size: 13px">To</label>
                    <div class="subtitle">
                        <span class="inner-screen" style="font-size: 14px">{{Destination}}
                        </span>
                    </div>
                </th>
                <th class="pl-2" style="width: 16.66%">
                    <label style="font-size: 13px">No. Of Boxes</label>
                    <div class="subtitle">
                        <span class="inner-screen" style="font-size: 14px">{{NoOfPackages}}
                        </span>
                    </div>
                </th>
               
                
            </tr>
            <tr>
                <th class="pl-2" style="width: 16.66%">
                    <label style="font-size: 13px"> Charged Weight</label>
                    <div class="subtitle">
                        <span class="inner-screen" style="font-size: 14px">{{dec_charged_weight}}
                        </span>
                    </div>
                </th>
                <th class="pl-2" style="width: 16.66%">
                    <label style="font-size: 13px">RIL Business</label>
                    <div class="subtitle">
                        <span class="inner-screen" style="font-size: 14px">{{vc_material_type}}
                        </span>
                    </div>
                </th>
                <th class="pl-2" style="width: 16.66%">
                    <label style="font-size: 13px">Status</label>
                    <div class="subtitle">
                        <span class="inner-screen" style="font-size: 14px">{{Status}}
                        </span>
                    </div>
                </th>
            </tr>
        </table>
    </div>
</div>
<mat-dialog-actions align="end" style="padding-bottom: 10px ;margin-right: 12px;">
    <button class="btn btn-primary fa fa-link" *ngIf="Redirect" (click)="openpage()"
    style="margin-right: 1% ; background-color: #286ba5 !important;">
    <span style="font-family: Arial, Helvetica, sans-serif;"[matTooltip]="ScreenName">
        Redirect to Page</span></button>  
    <button class="btn btn-success fa fa-download" (click)="generatePDF() "
        style="cursor: pointer; left: 6px; background-color: #28a745; color: white">
        <span style="font-family: Arial, Helvetica, sans-serif;"> Download PDF</span>
    </button>
    <button class="btn btn-danger fa fa-times-circle" (click)="close()"
        style="margin-left: 10px ; background-color: #f44336 !important;">
        <span style="font-family: Arial, Helvetica, sans-serif;">
            Cancel</span></button>
</mat-dialog-actions>