import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapsdialogsComponent } from '../../mapsdialogs/mapsdialogs.component';
@Component({
  selector: 'app-view-map-location',
  templateUrl: './view-map-location.component.html',
  styleUrls: ['./view-map-location.component.scss']
})
export class ViewMapLocationComponent implements OnInit {
  lat: any;
  lng: any;
  radius: any;
  constructor(
    public dialogRef: MatDialogRef<MapsdialogsComponent>, @Inject(MAT_DIALOG_DATA) public data: number) { }
  ngOnInit(): void {
    if (this.data != null) {
      if (this.data['data']['Latitude'] != null) {
        this.lat = Number(this.data['data']['Latitude']);
      }
      if (this.data['data']['Longitude'] != null) {
        this.lng = Number(this.data['data']['Longitude']);
      }
      if (this.data['data']['Radius'] != null) {
        this.radius = Number(this.data['data']['Radius']);
      }
    }
  }
  close(): void {
    this.dialogRef.close();
  }
}


