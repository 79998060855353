import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AlertService {

    private toasterService: ToastrService;

    constructor(
        toasterService: ToastrService
        ) {
        this.toasterService = toasterService;
    }

    createAlert(msg: string, type: number): void {
        if (type == 1) {
            this.toasterService.success(msg);
        }
        else if (type == 0) {
            this.toasterService.error(msg);
        }
    }
}