<div class="">
    <div class="card card-body ">
        <div class="row mt-2">
            <div class="col">
                <div widget class="card border-0 box-shadow">
                    <div class="card-body widget-body">
                        <div class="row form-inline">
                            <div class="form-group col-xl-1 col-sm-6 col-12" style="color: black;">Filter By:</div>
                            <div class="form-group col-xl-2 col-sm-6 col-12">
                                <mat-select placeholder="Filter by" style="width: 100%; border: 1px solid black;"
                                    onselect="reset()" placeholder="Filter by" [(ngModel)]="filterBy"
                                    class="form-control">
                                    <mat-option value="any">Any</mat-option>

                                    <mat-option value="vendor_code">Vendor Code</mat-option>
                                    <mat-option value="verdor_type">Vendor Name</mat-option>
                                    <mat-option value="vendor_location">Vendor Location</mat-option>
                                    <mat-option value="asn_no">ASN No</mat-option>
                                    <mat-option value="asn_date">ASN Date</mat-option>
                                    <mat-option value="vehicle_type">DC Site Code</mat-option>
                                    <mat-option value="vehicle_number">DC Site Location</mat-option>
                                    <mat-option value="driver_name">Upload Date</mat-option>

                                </mat-select>

                            </div>
                            <div class='col-sm-3' [ngSwitch]="filterBy">

                                <div *ngSwitchCase="'EffectiveDate'">
                                    <ng-container *ngTemplateOutlet="date"></ng-container>
                                </div>
                                <div *ngSwitchCase="'order_status'">
                                    <ng-container *ngTemplateOutlet="status"></ng-container>
                                </div>
                                <div *ngSwitchCase="'service_type'">
                                    <ng-container *ngTemplateOutlet="serviceclass"></ng-container>
                                </div>
                                <div *ngSwitchCase="'service'">
                                    <ng-container *ngTemplateOutlet="servicetype"></ng-container>
                                </div>
                                <div *ngSwitchDefault>
                                    <ng-container *ngTemplateOutlet="KeyValue"></ng-container>
                                </div>
                            </div>
                            <ng-template #KeyValue>
                                <div class="row">
                                    <div class="col-sm-3  form-group " style="color: black;">
                                        Keyword:
                                    </div>
                                    <div class="col-sm-9 ">
                                        <input style="width: 90%; border: 1px solid black;" type="text"
                                            [(ngModel)]="keyword" class="form-control " placeholder="Search by Keyword">
                                    </div>
                                </div>
                            </ng-template>

                            <div class="form-group col-sm-3">
                                <button class="btn btn-success fa fa-search">
                                    <span> Search</span>
                                </button>
                                <button class="btn fa fa-eraser" style="margin-left: 10px ; background-color:#778992; color: #fff;">
                                    <span> Clear</span></button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>



    <div class="col-sm-12 mt-2 p-0 ml-0 mr-0 tbl-container ">
        <div class="table-responsive tbl-fixed div1">

            <table class="table table-hover text-center table-bordered ">
                <thead class="padding:20px tbl-head">
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Name</th>
                        <th scope="col">State</th>
                        <th scope="col">City Name</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of OrderList let i=index">
                        <th scope="row">{{i+1}}</th>
                        <td>{{item.name}}</td>
                        <td>{{item.state}}</td>
                        <td>{{item.cityName}}</td>
                        <td>{{item.phoneNumber}}</td>
                        <td>
                            <button class="btn btn-danger btn-sm  action_btn" type="button" data-toggle="tooltip"
                                data-placement="top" title="Remove"><i class="fa fa-trash"></i>&nbsp;Remove</button>
                            &nbsp;
                            <button class="btn btn-success btn-sm action_btn" type="button" data-toggle="tooltip"
                                data-placement="top" title="Edit"><i class="fa fa-edit"></i>&nbsp;Edit</button>
                            <!-- <button type="button" class="btn btn-danger btn-action action_btn" data-toggle="tooltip" data-placement="top" title="Remove"><i class="fa fa-trash"></i>&nbsp; Remove</button> &nbsp;
            <button type="button" class="btn btn-success btn-action action_btn" data-toggle="tooltip" data-placement="top" title="Edit"><i class="fa fa-edit"></i>&nbsp; Edit</button>  -->
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>


    <mat-paginator [length]="totalSize" [pageIndex]="currentPage" [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="handlePage($event)" showFirstLastButtons>
    </mat-paginator>


</div>