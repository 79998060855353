import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MenuService } from 'src/app/theme/components/menu/menu.service';
import { TextValidator } from '../customers/text_validator';
import { PagesServiceService } from '../pages-service.service';
import { PreviewXpcnComponent } from '../preview-xpcn/preview-xpcn.component';

@Component({
  selector: 'app-generate-lr',
  templateUrl: './generate-lr.component.html',
  styleUrls: ['./generate-lr.component.scss']
})
export class GenerateLrComponent implements OnInit {
  vendorCode = '';
  dcCode = '';
  invValLessThan50 = false;
  noOfBoxes = 0;
  asnNo = '';
  invTotal = 0;
  amtInWords = '';
  today = new Date();
  minday = new Date();
  FT = 'FT';
  items = [];
  isValueEntered: boolean = false
  isEdit = false;
  disableExpDate = false

  access = {
    btCreate: true,
    btRead: true,
    btUpdate: true,
    btDelete: true
  };
  xpcn = {
    //'OrderId':null,
    'XPCNId': null
  };
  public popoverTitle: string = 'Confirm Delete';
  public popoverMessage: string = 'Are you sure you want to delete this record?';

  public popoverSaveTitle: string = 'Confirm Save Change';
  public popoverSaveMessage: string = 'Are you sure you want to save changes?';

  btSaving = false;
  xpcnNo = '';
  addEditForm: FormGroup;
  invForm: FormGroup;
  ewbForm: FormGroup;
  lclXPCNId: any = -1;
  isXPCNFinalized: boolean = false
  invoices: any[] = []
  waybills: any[] = []
  finalized = true
  isMXPCN = true
  goodsDescription: any[] = []
  goodsDescAll: any[] = []
  packageTypes: any[] = []
  riskTypes: any[] = []
  freightModes: any[] = []
  allStatesList = []
  statesListConsignor = [];
  statesListConsignee = [];
  districtsListConsignor = [];
  locationsListConsignor = [];
  postcodesListConsignor = [];
  districtsListConsignee = [];
  locationsListConsignee = [];
  postcodesListConsignee = [];
  CreditCode: boolean = false;
  FreightDisplaytype: boolean = true;
  total: number = 0
  rate: any = 0
  gst: any = 0
  CONSIGNOR: number = 1
  CONSIGNEE: number = 2
  consignorStateID: number = -1
  consigneeStateID: number = -1
  consignorDistrictID: number = -1
  consigneeDistrictID: number = -1
  consignorLocationID: number = -1
  consigneeLocationID: number = -1
  consignorPostCodeID: number = -1
  consigneePostCodeID: number = -1
  formData: FormData = new FormData();
  SAVE: number = 0
  FINALIZE: number = 1
  isSaveBoxesClicked: boolean = false;
  isFinalizeClicked: boolean = false;
  boxesChanged = false;
  bookingId = ""
  originStateId: any = -1
  originDistrictId: any = -1
  originLocationId: any = -1
  originPostcodeId: any = -1;
  totalPkgs = 0;
  totalVWt = 0;

  //Backend Model
  //  public class LCLXPCNBoxes { 
  //   public long? BoxId { get; set; }
  //      public long? XPCNId { get; set; }

  //      public decimal? Length { get; set; }
  //      public decimal? Breadth { get; set; }

  //      public decimal? Height { get; set; }

  //      public decimal? Weight { get; set; }

  //      public long? HUNumberId { get; set; }
  //      public string HUNumber { get; set; }
  //  }

  boxDetails: any[] = []
  boxDetailsReal: any[] = []
  helperBoxDetails: any[] = []
  boxesFromBackend: number = 0
  noOfBoxesAdded: number = 0
  SINGLE: number = 1
  MULTIPLE: number = 2
  isValueAddedCharges: boolean = false
  isCODDODCheckBoxEnabled = false
  isCODORDODChecked = true
  isBreakBulk: boolean = false
  deliveryRegionId: any = -1
  chargesPaidBy: any[] = [{ name: "FFV", value: true }, { name: "XP", value: false }]

  vacRemarksList: any[] = []
  // [{ id: 1, name: 'Sunday Delivery', amount: 1000 },
  // { id: 2, name: 'Public Holiday', amount: 2500 },
  // { id: 3, name: 'COD', amount: 0 },
  // { id: 4, name: 'DOD', amount: 0 }]
  deliveryStateIds: string = null
  deliveryStateNames: string = null
  checkServiceType: any;
  availableWeight: any = 0
  InputRequred: boolean;
  isBoxesCheckBox: boolean = false;
  BillingMethods: any;
  btClicked: boolean = false;
  duplicateDimensionError: boolean = false;


  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    public alertService: AlertService,
    // private _menuService: MenuService,
    private _pageService: PagesServiceService,
    private _datePipe: DatePipe,
    public _downloadExcelService: DownloadExcelService,
    public fb: FormBuilder,

  ) {
    this.today.setDate(this.today.getDate());
    this.minday.setDate(this.today.getDate() - 3);

    this.ewbForm = this.fb.group({
      XPCNId: new FormControl(''),
      InvoiceId: new FormControl(''),
      strOrderDate: new FormControl(''),
      InvoiceNo: new FormControl('', Validators.compose([Validators.required])),
      Date: new FormControl('', Validators.compose([Validators.required])),
      ExpDate: new FormControl('', Validators.compose([Validators.required])),
      InvoiceValue: new FormControl('', Validators.compose([Validators.required])),
      DocUrl: new FormControl(''),
      Doc: new FormControl('')
    });

    this.invForm = this.fb.group({
      XPCNId: new FormControl(''),
      InvoiceId: new FormControl(''),
      strOrderDate: new FormControl(''),
      InvoiceNo: new FormControl('', Validators.compose([Validators.required])),
      Date: new FormControl('', Validators.compose([Validators.required])),
      InvoiceValue: new FormControl('', Validators.compose([Validators.required])),
      DocUrl: new FormControl(''),
      Doc: new FormControl('')
    });

    this.addEditForm = this.fb.group({
      // SearchEWB: new FormControl(''),
      XPCNCode: new FormControl(null, Validators.required),
      strOrderDate: new FormControl(null),
      OrderIdCode: new FormControl(null, Validators.required),
      VehicleNo: new FormControl(null),
      From: new FormControl(null, Validators.required),
      To: new FormControl(null, Validators.required),
      ConsignorName: new FormControl(null),
      ConsigneeName: new FormControl(null),
      GoodsDescId: new FormControl(null, Validators.required),
      PackageTypeId: new FormControl(null),
      NoOfPackages: new FormControl(null, Validators.required),
      Weight: new FormControl(null, Validators.required),
      NameOfConsignor: new FormControl(null, Validators.required),
      ConsignorMobile: new FormControl(null),
      ConsignorEmail: new FormControl(null),
      ConsignorStateId: new FormControl(null, Validators.required),
      ConsignorState: new FormControl(null),
      ConsignorStateSearch: new FormControl(null),
      ConsignorDistrict: new FormControl(null, Validators.required),
      ConsignorDistrictSearch: new FormControl(null),
      ConsignorDistrictId: new FormControl(null, Validators.required),
      ConsignorLocationId: new FormControl(null, Validators.required),
      ConsignorLocationSearch: new FormControl(null),
      ConsignorPostcodeId: new FormControl(null, Validators.required),
      ConsignorPostcodeSearch: new FormControl(null),
      ConsignorGSTIN: new FormControl(null, Validators.required),
      ConsignorAddress: new FormControl(null, Validators.required),
      ConsignorAddress2: new FormControl(null),
      NameOfConsignee: new FormControl(null, Validators.required),
      ConsigneeMobile: new FormControl(null),
      ConsigneeEmail: new FormControl(null),
      ConsigneeState: new FormControl(null),
      ConsigneeStateSearch: new FormControl(null),
      ConsigneeStateId: new FormControl(null, Validators.required),
      ConsigneeDistrict: new FormControl(null, Validators.required),
      ConsigneeDistrictId: new FormControl(null, Validators.required),
      ConsigneeLocationId: new FormControl(null, Validators.required),
      ConsigneeLocationSearch: new FormControl(null),
      ConsigneePostcodeId: new FormControl(null, Validators.required),
      ConsigneePostcodeSearch: new FormControl(null),
      ConsigneeGSTIN: new FormControl(null, Validators.required),
      ConsigneeAddress: new FormControl(null, Validators.required),
      ConsigneeAddress2: new FormControl(null),
      SearchEWB: new FormControl(""),
      RiskTypeId: new FormControl(null),
      ModeOfFreightId: new FormControl(null),
      CreditCode: new FormControl(null),
      OTL: new FormControl(null),
      ChargesPaidByFFV: new FormControl(null),

      HandlingCharges: new FormControl(null),
      HaltingCharges: new FormControl(null),
      OtherCharges: new FormControl(null),
      GST: new FormControl(null),
      TotalCharges: new FormControl(null),
      IsFinalized: new FormControl(false, Validators.required),
      XPCNId: new FormControl(this.lclXPCNId, Validators.required),
      ConsignorLocation: new FormControl(null),
      ConsignorPostcode: new FormControl(null),
      ConsigneeLocation: new FormControl(null),
      ConsigneePostcode: new FormControl(null),
      isVAC: new FormControl(this.isValueAddedCharges),
      VACRemarkId: new FormControl(null),
      CODBODChecked: new FormControl(true),
      VACRemarkName: new FormControl(null),
      AmountToCollect: new FormControl(null),
      ValueAddedCharge: new FormControl(null),
      UsedStickerNo: new FormControl(null),
      ConsigneeId: new FormControl(null),
      BoxesList: new FormControl([]),
      BoxesListConsignor: new FormControl([]),
      BasicFreight: new FormControl(null),
      GSTLCL: new FormControl(null),
      SpecialRemarks: new FormControl(null),
      ChargedWeight: new FormControl(null),
      InvValueBelow50k: new FormControl(null, Validators.required),
      EWBId: new FormControl(null),
      DCCode: new FormControl(null),
      InvId: new FormControl(null),
      EWBNo: new FormControl(null),
      EWBExpDate: new FormControl(null),
      InvNo: new FormControl(null),
      InvDate: new FormControl(null),
      InvAmt: new FormControl(null),
      LR_PO: new FormControl(null)
    });
  }

  ngOnInit() {
    this.lclXPCNId = atob(this._activeRoute.snapshot.queryParams['x']);
    this.asnNo = atob(this._activeRoute.snapshot.queryParams['y']);
    this.vendorCode = this._activeRoute.snapshot.queryParams['z'];
    this.isEdit = this._activeRoute.snapshot.queryParams['a'] == 1 ? true : false;
    // this.GetAllConsignee();
    this.getXPCNDetailsForFinalization(true);
    this.addEditForm.controls['LR_PO'].setValue(this.asnNo);

  }

  back() {
    if (this.isEdit)
      this._router.navigate(['/xp/print-lr-tracking']);
    else
      this._router.navigate(['/xp/asn-list']);
  }

  // getVACReasons(codCharges: number, dodChargers: number) {
  //   this._pageService.getVACReasons().subscribe(res => {
  //     this.vacRemarksList = res['Data']
  //     this.vacRemarksList.push({ id: -3, name: 'COD', amount: codCharges })
  //     this.vacRemarksList.push({ id: -4, name: 'DOD', amount: dodChargers })
  //   })
  // }

  data: any = null
  getXPCNDetailsForFinalization(setFormControls: boolean) {

    this._pageService.getLCLXPCNDetailsForFinzalization(this.lclXPCNId).subscribe(response => {

      this.data = response['Data'];
      // console.log(this.data);
      // this.getVACReasons(this.data["COCCharges"], this.data["DODCharges"]);
      this.bookingId = this.data["BookingId"]
      this.originStateId = this.data["OriginStateId"]
      this.originDistrictId = this.data["OriginDistrictId"]
      this.originLocationId = this.data["OriginLocationId"]
      this.originPostcodeId = this.data["OriginPostcodeId"]

      this.isBreakBulk = this.data['ServiceType'] === 'LCL Breakbulk' || this.data['ServiceType'] === 'LCL Direct - Delivery'
      this.availableWeight = this.data['AvailableWeight']
      this.deliveryRegionId = this.data['DeliveryRegionId']
      this.goodsDescription = this.data['GoodsDescs'].filter(x => x.LookupName == 'Fashion Lifestyle' || x.LookupName == 'Mens Fashion Bottoms and others' || x.LookupName == 'Shoes & Fashion Accessories' || x.LookupName == 'HOSIERY GOODS');
      this.goodsDescAll = this.data['GoodsDescs'];
      this.packageTypes = this.data['PackageTypes']
      this.riskTypes = this.data['RiskTypes']

      this.freightModes = this.data['FreightModes']
      this.checkServiceType = this.data['ServiceType'];
      this.isXPCNFinalized = this.data['IsFinalized']
      this.deliveryStateIds = this.data['DeliveryStateIds']
      this.deliveryStateNames = this.data['DeliveryStateNames']
      this.gst = this.data['GST'];
      this.dcCode = this.data['DCCode'];
      this.getStatesList(this.data);

      if (setFormControls) {
        this.xpcnNo = this.data["XPCNCode"];
        let formControls = [{ name: "XPCNCode", value: this.data["XPCNCode"] }, { name: "strOrderDate", value: this.data["strOrderDate"] },
        { name: "VehicleNo", value: this.data["VehicleNo"] }, { name: "From", value: this.data["From"] },
        { name: "OrderIdCode", value: this.data['OrderIdCode'] },
        { name: "NameOfConsignor", value: this.data['ConsignorName'] },
        { name: "DCCode", value: this.data['DCCode'] },
        { name: "InvValueBelow50k", value: this.data['InvValueBelow50k'] == true ? "true" : "false" },
        { name: "ConsignorGSTIN", value: this.data['ConsignorGSTIN'] == null ? null : this.data['ConsignorGSTIN'].trim() },
        { name: "ConsignorAddress", value: this.data['ConsignorAddress'] },
        { name: "ConsignorAddress2", value: this.data['ConsignorAddress2'] },
        { name: "NameOfConsignee", value: this.data['ConsigneeName'] },
        { name: "ConsigneeGSTIN", value: this.data['ConsigneeGSTIN'] == null ? null : this.data['ConsigneeGSTIN'].trim() },
        { name: "ConsigneeAddress", value: this.data['ConsigneeAddress'] },
        { name: "ConsigneeAddress2", value: this.data['ConsigneeAddress2'] },
        { name: "ConsigneeId", value: this.data['ConsigneeId'] },
        { name: "ConsignorName", value: this.data['ConsignorName'] },
        { name: "ConsigneeName", value: this.data['ConsigneeName'] },
        { name: "ConsignorStateId", value: this.data['ConsignorStateId'] },
        { name: "ConsignorState", value: this.data['ConsignorState'] },
        { name: "ConsigneeStateId", value: this.data['ConsigneeStateId'] },
        { name: "ConsigneeState", value: this.data['ConsigneeState'] },
        { name: "ConsignorMobile", value: this.data['ConsignorContact'] },
        { name: "ConsignorEmail", value: this.data['ConsignorEmail'] },
        { name: "ConsigneeMobile", value: this.data['ConsigneeContact'] },
        { name: "ConsigneeEmail", value: this.data['ConsigneeEmail'] },
        { name: "GoodsDescId", value: this.data['GoodsDescId'] },
        { name: "NoOfPackages", value: this.data['NoOfPackages'] },
        { name: "Weight", value: this.data['Weight'] },
        { name: "RiskTypeId", value: this.data['RiskTypeId'] == null ? 46 : this.data['RiskTypeId'] },
        { name: "ModeOfFreightId", value: this.data['ModeOfFreightId'] },
        { name: "IsFinalized", value: this.data['IsFinalized'] },
        { name: "PackageTypeId", value: this.data['PackageTypeId'] },
        { name: "ChargesPaidByFFV", value: this.data['ChargesPaidByFFV'] == null ? false : this.data['ChargesPaidByFFV'] },
        { name: "SpecialRemarks", value: this.data['SpecialRemarks'] },
        { name: "BasicFreight", value: this.data['BasicFreight'] },
        { name: "OtherCharges", value: this.data['OtherCharges'] },
        { name: "ChargedWeight", value: this.data['xpcn_charged_weight'] }

        ]
        this.invValChanged(this.data['InvValueBelow50k'] == true ? "true" : "false");

        if (this.freightModes.filter(x => x.LookupId == this.data['ModeOfFreightId']).length > 0) {
          this.BillingMethods = this.freightModes.filter(x => x.LookupId == this.data['ModeOfFreightId'])[0].LookupName;
        }
        this.setValuesForAddEditFormControl(formControls);
        this.setLocationDetails(this.data);
        this.setVACIfExists(this.data);
        this.boxDetails = this.data['BoxesListConsignor'];

        if (this.boxDetails.length == 0 && this.addEditForm.controls['NoOfPackages'].value != null && this.addEditForm.controls['NoOfPackages'].value != '') {
          this.boxDetails.push({
            BoxId: null,
            XPCNId: this.lclXPCNId,
            Length: 0,
            Breadth: 0,
            Height: 0,
            Pkgs: Number(this.addEditForm.controls['NoOfPackages'].value),
            Weight: 0,
            HUNumber: null,
            HUBarcodeUrl: null
          });
        }
      }
      this.calculateTotalPkgsWt();

      this.invoices = this.data['Invoices'];
      this.waybills = this.data['WayBills'];

      if (this.invoices.length > 0) {
        //let date1 = new Date(this._datePipe.transform(this.invoices[0].Date, 'yyyy-MM-dd'));

        let date1 = null
        if(this.invoices[0].Date != null){
          date1 = new Date(this._datePipe.transform(this.invoices[0].Date, 'yyyy-MM-dd'));
        }

        this.addEditForm.controls['InvId'].setValue(this.invoices[0].InvoiceId);
        this.addEditForm.controls['InvNo'].setValue(this.invoices[0].InvoiceNo);
        this.addEditForm.controls['InvDate'].setValue(date1);
        this.addEditForm.controls['InvAmt'].setValue(this.invoices[0].InvoiceValue);
        this.invAmtChanged();
        this.invForm.controls['XPCNId'].setValue(this.data['xpcnId']);
        this.invForm.controls['InvoiceId'].setValue(this.invoices[0].InvoiceId);
        this.invForm.controls['InvoiceNo'].setValue(this.invoices[0].InvoiceNo);
        this.invForm.controls['Date'].setValue(date1);
        this.invForm.controls['InvoiceValue'].setValue(this.invoices[0].InvoiceValue);
        this.invForm.controls['DocUrl'].setValue(this.invoices[0].DocUrl);

        // if (this.invoices[0].InvoiceValue > 50000) {
        //   this.addEditForm.controls['InvValueBelow50k'].setValue("Yes");
        // } else {
        //   this.addEditForm.controls['InvValueBelow50k'].setValue("No");
        // }

      }
      if (this.waybills.length > 0) {
        // let date1 = new Date(this._datePipe.transform(this.waybills[0].Date, 'yyyy-MM-dd'));
        // let date2 = new Date(this._datePipe.transform(this.waybills[0].ExpDate, 'yyyy-MM-dd'));

        let date1 = null
        if(this.waybills[0].Date != null){
          date1 = new Date(this._datePipe.transform(this.waybills[0].Date, 'yyyy-MM-dd'));
        }

        let date2 = null
        if(this.waybills[0].ExpDate != null){
          date2 = this._datePipe.transform(this.waybills[0].ExpDate, 'yyyy-MM-dd');
          // date2 = new Date(this._datePipe.transform(this.waybills[0].ExpDate, 'yyyy-MM-dd'));
          this.disableExpDate = false
        }
        else{
          this.disableExpDate = true
        }

        this.addEditForm.controls['EWBId'].setValue(this.waybills[0].InvoiceId);
        this.addEditForm.controls['EWBNo'].setValue(this.waybills[0].InvoiceNo);
        this.addEditForm.controls['EWBExpDate'].setValue(date2);

        this.ewbForm.controls['XPCNId'].setValue(this.data['xpcnId']);
        this.ewbForm.controls['InvoiceId'].setValue(this.waybills[0].InvoiceId);
        this.ewbForm.controls['InvoiceNo'].setValue(this.waybills[0].InvoiceNo);
        this.ewbForm.controls['Date'].setValue(date1);
        this.ewbForm.controls['ExpDate'].setValue(date2);
        this.ewbForm.controls['InvoiceValue'].setValue(this.waybills[0].InvoiceValue);
        this.ewbForm.controls['DocUrl'].setValue(this.waybills[0].DocUrl);
      }

      // if (this.ConsigneeDetails.length > 0 && (this.data['ConsigneeName'] != null && this.data['ConsigneeName'] != "")) {
      //   let ConsigneeNameFilt = this.ConsigneeDetails.filter(x => x.CodeMasterName == this.data['ConsigneeName'])[0].LookupId;
      //   if (ConsigneeNameFilt != null || ConsigneeNameFilt > 0) {
      //     this.setValuesForAddEditFormControl(
      //       [{ name: "ConsigneeId", value: ConsigneeNameFilt }])
      //   }
      // }

      this.getLCLXPCNBoxDetails();
    })

  }

  onInputChange(value: string) {
    if (value.length < 10) {
      this.addEditForm.get('EWBNo').setErrors({ 'minlengthStrict': true });
    } else {
      this.addEditForm.get('EWBNo').setErrors(null);
    }
  }

  setAddEditFormValidator(keyName: string) {
    this.addEditForm.get(keyName).setValidators(Validators.required)
    this.addEditForm.get(keyName).updateValueAndValidity()
  }

  clearAddEditFormValidator(keyName: string, clearValue: boolean) {
    this.addEditForm.get(keyName).clearValidators()
    this.addEditForm.get(keyName).updateValueAndValidity()

    if (clearValue) {
      this.setValuesForAddEditFormControl([{ name: keyName, value: null }])
    }
  }


  setValuesForAddEditFormControl(formControls: { name: string, value: any }[]) {
    /*If fromControl keys are more than 1 then forEach executed,
     if not no need of forEach execution*/
    if (formControls.length > 1) {

      formControls.forEach(formControl => {
        this.addEditForm.controls[formControl.name].setValue(formControl.value)
      })
    }
    else {
      this.addEditForm.controls[formControls[0].name].setValue(formControls[0].value)


    }
    // this.calculateTotal(this.data['BasicFreight'], this.data['ValueAddedCharge'], this.gst)

  }



  FilterDescription(value) {
    if (value === '') {
      this.goodsDescription = this.goodsDescAll;
      return
    }
    const filterValue = value.toLowerCase();
    this.goodsDescription = this.goodsDescAll.filter(option => option.LookupName != null && option.LookupName != '' && option.LookupName.toLowerCase().includes(filterValue));
  }

  searchEwaybill() {
    // this._pageService.checkxpcnfinalizestatus(this.lclXPCNId).subscribe(res => {
      // if (!res['Data']) {
        if (this.getValueOfAddEditFormControl('SearchEWB') != null && this.getValueOfAddEditFormControl('SearchEWB') != '') {
          if (this.getValueOfAddEditFormControl('SearchEWB').length == 12) {
            this._pageService.searchLCLEWayBill(this.lclXPCNId, this.getValueOfAddEditFormControl('SearchEWB'), this.getValueOfAddEditFormControl('ConsignorStateId')).subscribe(response => {
              if (response['Success']) {
                this.getXPCNDetailsForFinalization(true)
              }
            })
          } else
            this.alertService.createAlert('Enter a valid EWaybill number', 0);
        }
      // }
      // else {
      //   this.alertService.createAlert('XPCN already finalized', 2);
      // }

    // })
  }

  getValueOfAddEditFormControl(keyName: string): any {
    return this.addEditForm.controls[keyName].value
  }

  getStatesList(data) {

    this.statesListConsignee = []
    this.statesListConsignor = []
    this._pageService.GetStatesDropdownlist().subscribe(response => {

      this.allStatesList = response['Data']
      //this.statesListConsignor = this.allStatesList


      //------------ Code to remove if no restriction of states and make statesListConsignor = this.allStatesList  -----------
      if (this.originStateId > 0) {
        let stateDetails = this.allStatesList.filter(x => x.StateId == this.originStateId)[0]

        this.statesListConsignor.push({ "StateId": stateDetails["StateId"], "StateName": stateDetails["StateName"], "StateGSTIN": stateDetails["StateGSTIN"] })
        if (this.statesListConsignor.length > 0) {
          this.setValuesForAddEditFormControl(
            [{ name: "ConsignorState", value: this.statesListConsignor[0].StateName },
            { name: "ConsignorStateId", value: this.statesListConsignor[0].StateId }])
        }

        if (this.getValueOfAddEditFormControl("ConsignorDistrictId") == null || typeof (this.getValueOfAddEditFormControl("ConsignorDistrictId")) == "undefined")
          this.stateChanged(this.getValueOfAddEditFormControl("ConsignorStateId"), this.CONSIGNOR)
      }

      //------------------------------------------------------------------------

      //If booking type is breakbulk, restrict the states list with delivery states selected at the time of booking  
      if (!this.isBreakBulk) {
        this.statesListConsignee = this.allStatesList
      }
      else {
        if (this.allStatesList.length > 0) {
          let deliveryStateIdList = this.deliveryStateIds.split(',');
          let deliveryStateNameList = this.deliveryStateNames.split(',')
          if (deliveryStateIdList.length === deliveryStateNameList.length) {
            for (let i = 0; i < deliveryStateIdList.length; i++) {
              let stateDetails = this.allStatesList.filter(x => x.StateId == deliveryStateIdList[i])[0]
              this.statesListConsignee.push({ "StateId": stateDetails["StateId"], "StateName": stateDetails["StateName"], "StateGSTIN": stateDetails["StateGSTIN"] })
            }


          }
          else {
            this.statesListConsignee = []
          }
        }
      }




    })



  }

  stateChanged(stateId: any, type: number) {
    if (type == this.CONSIGNOR) {
      this.consignorStateID = stateId
      let selectedState = this.statesListConsignor.filter(x => x.StateId == stateId)[0]
      this.setValuesForAddEditFormControl([{ name: "ConsignorState", value: selectedState.StateName }])
      this.locationsListConsignor = null;
      this.postcodesListConsignor = null;
    }
    else if (type == this.CONSIGNEE) {
      this.consigneeStateID = stateId
      let selectedState = this.statesListConsignee.filter(x => x.StateId == stateId)[0]
      this.setValuesForAddEditFormControl([{ name: "ConsigneeState", value: selectedState.StateName }])
      this.locationsListConsignee = null;
      this.postcodesListConsignee = null;
    }
    this.getDistricts(stateId, type)
  }


  getDistricts(stateId: any, type: any) {
    if (stateId != null) {
      this._pageService.GetDistrictsDropdownlist(stateId).subscribe(response => {

        if (type === this.CONSIGNOR) {

          this.districtsListConsignor = response['Data']

          //------------ Code to remove if no restriction of district -----------
          if (this.districtsListConsignor.length > 0 && this.originDistrictId > 0) {
            let districtDetails = this.districtsListConsignor.filter(x => x.DistrictId == this.originDistrictId)[0]

            this.setValuesForAddEditFormControl(
              [{ name: "ConsignorDistrict", value: districtDetails.DistrictName },
              { name: "ConsignorDistrictId", value: districtDetails.DistrictId }])

            if (this.getValueOfAddEditFormControl("ConsigneeLocationId") == null || typeof (this.getValueOfAddEditFormControl("ConsigneeLocationId")) == "undefined") {
              this.districtChanged(this.getValueOfAddEditFormControl("ConsignorDistrictId"), this.CONSIGNOR)
            }

          }
          //--------------------------------------------------------------------


        }
        else if (type == this.CONSIGNEE) this.districtsListConsignee = response['Data']
      })
    }

  }

  districtChanged(districtId: any, type: number) {
    if (type === this.CONSIGNOR) {
      this.consignorDistrictID = districtId
      this.postcodesListConsignor = null;
      this.getLocations(districtId, type)
    }
    else if (type == this.CONSIGNEE) {
      this.consigneeDistrictID = districtId
      this.postcodesListConsignee = null;
      this.CheckGatewayAvailabiity(districtId, type, this.lclXPCNId);
      // this._pageService.CheckGatewayForDistrict(districtId).subscribe(res => {
      //   if (res['Success']) {
      //     this.getLocations(districtId, type)
      //   }
      // })
    }
    // if (type == this.CONSIGNEE) {
    //   this._pageService.CheckGatewayForDistrict(districtId).subscribe(res => {
    //     if (res['Success']) {
    //       this.getLocations(districtId, type)
    //     }
    //   })
    // }
    // else { this.getLocations(districtId, type) }

  }


  getLocations(districtId: any, type: number) {
    if (districtId != null) {
      this._pageService.GetLocationsDropdownlist(districtId).subscribe(response => {
        if (type === this.CONSIGNOR) {
          this.locationsListConsignor = response['Data'];

          //------------ Code to remove if no restriction of Location -----------
          if (this.locationsListConsignor.length > 0 && this.originLocationId > 0) {
            let locationDetails = this.locationsListConsignor.filter(x => x.LocationId == this.originLocationId)[0]

            this.setValuesForAddEditFormControl(
              [{ name: "ConsignorLocationId", value: locationDetails.LocationId }])

            if (this.getValueOfAddEditFormControl("ConsigneePostcodeId") == null || typeof (this.getValueOfAddEditFormControl("ConsigneePostcodeId")) == "undefined") {
              this.locationChanged(this.getValueOfAddEditFormControl("ConsignorLocationId"), this.CONSIGNOR)
            }

          }
          //--------------------------------------------------------------------

        }
        else if (type == this.CONSIGNEE) {
          this.locationsListConsignee = response['Data']

          if (this.getValueOfAddEditFormControl('ConsigneeLocationId') != null) {
            this.setValuesForAddEditFormControl([{ name: 'To', value: this.locationsListConsignee.length > 0 ? this.locationsListConsignee.filter(x => x.LocationId == this.getValueOfAddEditFormControl('ConsigneeLocationId'))[0].LocationName : null }])

          }
        }
      })
    }
  }

  locationChanged(locationId: any, type: number) {
    if (type === this.CONSIGNOR) {
      this.setValuesForAddEditFormControl([{ name: 'From', value: this.locationsListConsignor.filter(x => x.LocationId == locationId)[0].LocationName }])
    }
    else if (type == this.CONSIGNEE) {
      this.setValuesForAddEditFormControl([{ name: 'To', value: this.locationsListConsignee.filter(x => x.LocationId == locationId)[0].LocationName }])
    }
    this.getPostCodes(locationId, type)
  }

  getPostCodes(locationId: any, type: number) {
    if (locationId != null) {
      this._pageService.GetPostcodesDropdownlist(locationId).subscribe(response => {
        if (type === this.CONSIGNOR) {
          this.postcodesListConsignor = response['Data']
          //------------ Code to remove if no restriction of postcode -----------
          if (this.postcodesListConsignor.length > 0 && this.originPostcodeId > 0) {
            let postcodeDetails = this.postcodesListConsignor.filter(x => x.PostcodeId == this.originPostcodeId)[0]

            this.setValuesForAddEditFormControl(
              [{ name: "ConsignorPostcodeId", value: postcodeDetails.PostcodeId }])



          }
          //--------------------------------------------------------------------

        }
        else if (type == this.CONSIGNEE) this.postcodesListConsignee = response['Data']
      })
    }
  }

  // public addEditInvoices(invoice) {
  //   this._pageService.checkxpcnfinalizestatus(this.lclXPCNId).subscribe(res => {
  //     if (!res['Data']) {
  //       let dialogRef = this.dialog.open(AddEditXpcnInvoicesLclComponent, {
  //         data: { invoice: invoice, xpcnId: this.lclXPCNId, orderDate: this.getValueOfAddEditFormControl('strOrderDate') },
  //         height: 'auto',
  //         width: '600px',
  //         autoFocus: false
  //       });
  //       dialogRef.afterClosed().subscribe(data => {
  //         if (data.isInvoiceAdded) {
  //           this.getXPCNDetailsForFinalization(false)
  //         }

  //       });
  //     }
  //     else {
  //       this.alertService.createAlert('XPCN already finalized', 2);
  //     }

  //   })

  // }

  // deleteInvoice(item) {
  //   this._pageService.deleteLCLXPCNInvoice(item).subscribe(res => {
  //     if (res['Success']) {
  //       let deletedInvoiceIndex = this.invoices.findIndex((object) => {
  //         return object.InvoiceId == item.InvoiceId
  //       })

  //       this.invoices.splice(deletedInvoiceIndex, 1)
  //     }
  //   })
  // }

  // deleteWaybill(item) {
  //   this._pageService.deleteLCLXPCNWayBill(item).subscribe(res => {
  //     if (res['Success']) {
  //       let deletedWayBillIndex = this.waybills.findIndex((object) => {
  //         return object.InvoiceId == item.InvoiceId
  //       })

  //       this.waybills.splice(deletedWayBillIndex, 1)
  //     }
  //   })
  // }

  // public addEditEWayBills(wayBill) {
  //   this._pageService.checkxpcnfinalizestatus(this.lclXPCNId).subscribe(res => {
  //     if (!res['Data']) {
  //       let dialogRef = this.dialog.open(AddEditEwaybillsLclComponent, {
  //         data: { wayBill: wayBill, xpcnId: this.lclXPCNId, xplsId: null, orderDate: this.getValueOfAddEditFormControl('strOrderDate') },
  //         height: 'auto',
  //         width: '600px',
  //         autoFocus: false
  //       });
  //       dialogRef.afterClosed().subscribe(data => {
  //         if (data.isEwayBillAdded) {
  //           this.getXPCNDetailsForFinalization(false)
  //         }

  //       });
  //     }
  //     else {
  //       this.alertService.createAlert('XPCN already finalized', 2);
  //     }

  //   })
  // }

  onFreightChange() {
    // console.log('Alert',this.addEditForm.controls['ModeOfFreightId'].value)

  }

  OnFreightAdd() {
    this.rate = this.addEditForm.controls['BasicFreight'].value;
    this.calculateTotal(this.rate, this.getValueOfAddEditFormControl("ValueAddedCharge") == null ? 0 : this.getValueOfAddEditFormControl("ValueAddedCharge"), this.gst);
  }

  OnOtherChargesAdd() {
    this.calculateTotal(this.rate, this.getValueOfAddEditFormControl("ValueAddedCharge") == null ? 0 : this.getValueOfAddEditFormControl("ValueAddedCharge"), this.gst);
  }


  priceValidator(event) {
    return TextValidator.priceValidator(event);
  }

  calculateTotal(basicFreight: number = 0, vasCharges: number = 0, gst: number = 0) {
    this.total = 0;
    let handling = this.addEditForm.controls['HandlingCharges'].value == null ? 0 : this.addEditForm.controls['HandlingCharges'].value;
    let halting = this.addEditForm.controls['HaltingCharges'].value == null ? 0 : this.addEditForm.controls['HaltingCharges'].value;
    let others = this.addEditForm.controls['OtherCharges'].value == null ? 0 : this.addEditForm.controls['OtherCharges'].value;

    let Gst = ((Number(basicFreight) + Number(handling) + Number(halting) + Number(others) + vasCharges) * gst) / 100;

    this.addEditForm.controls['GSTLCL'].setValue(Gst);

    this.total = Number(basicFreight) + Number(handling) + Number(halting) + Number(others) + Number(Gst) + vasCharges;

    this.addEditForm.controls['TotalCharges'].setValue(this.total);
  }


  finalizeXPCN(action: number) {
    // this.btClicked = true;
    this._pageService.checkxpcnfinalizestatus(this.lclXPCNId).subscribe(res => {
      if (!res['Data'] || this.isEdit) {
        this.isFinalizeClicked = true
        this.setValuesForAddEditFormControl([{ name: 'XPCNId', value: this.lclXPCNId }])
        this.setValuesForAddEditFormControl([{ name: 'IsFinalized', value: false }])
        this.setValuesForAddEditFormControl([{ name: 'ConsignorName', value: this.getValueOfAddEditFormControl('NameOfConsignor') }])
        this.setValuesForAddEditFormControl([{ name: 'ConsigneeName', value: this.getValueOfAddEditFormControl('NameOfConsignee') }])




        let locationDetails =
          [
            { formControlId: 'ConsignorStateId', formControlName: 'ConsignorState', list: this.statesListConsignor, listKeyName: 'StateId', listKeyValue: 'StateName' },
            { formControlId: 'ConsigneeStateId', formControlName: 'ConsigneeState', list: this.statesListConsignee, listKeyName: 'StateId', listKeyValue: 'StateName' },
            { formControlId: 'ConsignorDistrictId', formControlName: 'ConsignorDistrict', list: this.districtsListConsignor, listKeyName: 'DistrictId', listKeyValue: 'DistrictName' },
            { formControlId: 'ConsigneeDistrictId', formControlName: 'ConsigneeDistrict', list: this.districtsListConsignee, listKeyName: 'DistrictId', listKeyValue: 'DistrictName' },
            { formControlId: 'ConsignorLocationId', formControlName: 'ConsignorLocation', list: this.locationsListConsignor, listKeyName: 'LocationId', listKeyValue: 'LocationName' },
            { formControlId: 'ConsigneeLocationId', formControlName: 'ConsigneeLocation', list: this.locationsListConsignee, listKeyName: 'LocationId', listKeyValue: 'LocationName' },
            // { formControlId: 'ConsignorPostcodeId', formControlName: 'ConsignorPostcode', list: this.postcodesListConsignor, listKeyName: 'PostcodeId', listKeyValue: 'PostcodeName' },
            // { formControlId: 'ConsigneePostcodeId', formControlName: 'ConsigneePostcode', list: this.postcodesListConsignee, listKeyName: 'PostcodeId', listKeyValue: 'PostcodeName' },
          ]

        // console.log(this.addEditForm.controls['ConsignorPostcodeId'].value);
        // console.log(this.addEditForm.controls['ConsignorPostcode'].value);
        // console.log(this.addEditForm.controls['ConsigneePostcodeId'].value);
        // console.log(this.addEditForm.controls['ConsigneePostcode'].value);
        // console.log(this.postcodesListConsignor);
        // console.log(this.postcodesListConsignee);

        locationDetails.forEach(data => {
          this.setFormControlNameWithId(data.formControlId, data.formControlName, data.list, data.listKeyName, data.listKeyValue)
        })



        this.formData.delete('FormData')

        if (this.isBreakBulk && this.getValueOfAddEditFormControl('Weight') != null && this.getValueOfAddEditFormControl('Weight') > this.availableWeight) {
          this.alertService.createAlert(`Weight is exceeding the payload.\n  Available Weight is ${this.availableWeight} Kgs`, 0)

          return;
        }

        //  const invalid = [];
        //  const controls = this.addEditForm.controls;
        //  for (const name in controls) {
        //      if (controls[name].invalid) {
        //          invalid.push(name);
        //      }
        //  }
        // console.log(invalid)

        // if (this.addEditForm.valid) {
        if (this.addEditForm.controls['XPCNCode'].value != null
          && this.addEditForm.controls['OrderIdCode'].value != null
          && this.addEditForm.controls['From'].value != null
          && this.addEditForm.controls['To'].value != null
          && this.addEditForm.controls['GoodsDescId'].value != null
          && this.addEditForm.controls['NoOfPackages'].value != null
          && this.addEditForm.controls['Weight'].value != null
          && this.addEditForm.controls['NameOfConsignor'].value != null
          && this.addEditForm.controls['ConsignorStateId'].value != null
          && this.addEditForm.controls['ConsignorDistrict'].value != null
          && this.addEditForm.controls['ConsignorDistrictId'].value != null
          && this.addEditForm.controls['ConsignorLocationId'].value != null
          && this.addEditForm.controls['ConsignorPostcodeId'].value != null
          && this.addEditForm.controls['ConsignorGSTIN'].value != null
          && this.addEditForm.controls['ConsignorAddress'].value != null
          && this.addEditForm.controls['NameOfConsignee'].value != null
          && this.addEditForm.controls['ConsigneeStateId'].value != null
          && this.addEditForm.controls['ConsigneeDistrict'].value != null
          && this.addEditForm.controls['ConsigneeDistrictId'].value != null
          && this.addEditForm.controls['ConsigneeLocationId'].value != null
          && this.addEditForm.controls['ConsigneePostcodeId'].value != null
          && this.addEditForm.controls['ConsigneeGSTIN'].value != null
          && this.addEditForm.controls['ConsigneeAddress'].value != null
          && this.addEditForm.controls['IsFinalized'].value != null
          && this.addEditForm.controls['XPCNId'].value != null) {

          // console.log(this.boxDetails);

          let totalPkgs = 0;
          let chargedWeight = 0;
          let mainWeight = 0;
          if (this.boxDetails.length == 0) {
            this.alertService.createAlert("Please Add Boxes", 0);
            return;
            // } else if (this.boxDetails.filter(x => x.Length == null || x.Length == '' || x.Breadth == null || x.Breadth == ''
            //   || x.Height == null || x.Height == '' || x.Pkgs == null || x.Pkgs == '').length > 0) {
            //   this.alertService.createAlert('Length, Breadth, Height and PkKGS of all Docket Items are required.', 0);
            //   return;
          } else if (this.boxDetails.filter(x => Number(x.Length) == 0 || Number(x.Breadth) == 0 || Number(x.Height) == 0 || Number(x.Pkgs) == 0).length > 0) {
            this.alertService.createAlert('Length, Breadth, Height and PkKGS of all Docket Items must be greater than 0.', 0);
            return;
          } else {
            this.boxDetails.forEach(x => {
              totalPkgs += Number(x.Pkgs);
              chargedWeight += Number(x.Weight);

              //for calculating total Charged Weight
              let individualMainWeight = Number(x.Weight) / Number(x.Pkgs);
              individualMainWeight = Math.min(individualMainWeight, 21.00);
              mainWeight += individualMainWeight * Number(x.Pkgs);
            });

            let actWeight = Number(this.addEditForm.controls['Weight'].value);

            // this.addEditForm.controls['ChargedWeight'].setValue(actWeight > chargedWeight ? actWeight : chargedWeight);
            this.addEditForm.controls['ChargedWeight'].setValue(mainWeight);

            if (totalPkgs != Number(this.addEditForm.controls['NoOfPackages'].value)) {
              this.alertService.createAlert("PKGS in Consignment Details must match sum of PKGS Docket Items", 0);
              return;
            }
          }

          if (this.addEditForm.controls['InvValueBelow50k'].value == null) {
            this.alertService.createAlert('Please select Invoice Value Below Rs. 50K.', 0);
            return;
          } else if (this.addEditForm.controls['InvValueBelow50k'].value == "false"
            && (this.addEditForm.controls['EWBNo'].value == null
              || this.addEditForm.controls['EWBNo'].value == ''
              || (this.addEditForm.controls['EWBExpDate'].value == null && !this.disableExpDate)
              || (this.addEditForm.controls['EWBExpDate'].value == '' && !this.disableExpDate)
              || this.addEditForm.controls['InvNo'].value == null
              || this.addEditForm.controls['InvNo'].value == ''
              || this.addEditForm.controls['InvDate'].value == null
              || this.addEditForm.controls['InvDate'].value == ''
              || this.addEditForm.controls['InvAmt'].value == null
              || this.addEditForm.controls['InvAmt'].value == '')) {
            this.alertService.createAlert('Please provide Docket Invoices details.', 0);
            return;
          }
          // else if (this.addEditForm.controls['InvValueBelow50k'].value == "true"
          //   && (this.addEditForm.controls['InvNo'].value == null
          //     || this.addEditForm.controls['InvNo'].value == ''
          //     || this.addEditForm.controls['InvDate'].value == null
          //     || this.addEditForm.controls['InvDate'].value == ''
          //     || this.addEditForm.controls['InvAmt'].value == null
          //     || this.addEditForm.controls['InvAmt'].value == '')
          //     ) {
          //   this.alertService.createAlert('Please provide Docket Invoices details.', 0);
          //   return;
          // } 
          else if (this.addEditForm.controls['InvValueBelow50k'].value == "false" && Number(this.addEditForm.controls['InvAmt'].value) < 50000) {
            this.alertService.createAlert('Inv Amt must be more than 50000.', 0);
            return;
          } else if (this.addEditForm.controls['InvValueBelow50k'].value == "true" && Number(this.addEditForm.controls['InvAmt'].value) > 50000) {
            this.alertService.createAlert('Inv Amt must be less than 50000.', 0);
            return;
          } else {
            let date1 = null
            if(this.addEditForm.controls['InvDate'].value != null){
              date1 = new Date(this._datePipe.transform(this.addEditForm.controls['InvDate'].value, 'yyyy-MM-dd'));
            }

            this.invForm.controls['XPCNId'].setValue(this.lclXPCNId);
            this.invForm.controls['InvoiceId'].setValue(this.invoices.length == 0 ? null : this.invoices[0].InvoiceId);
            this.invForm.controls['InvoiceNo'].setValue(this.addEditForm.controls['InvNo'].value);
            this.invForm.controls['Date'].setValue(date1);
            this.invForm.controls['InvoiceValue'].setValue(this.addEditForm.controls['InvAmt'].value);
            this.invForm.controls['DocUrl'].setValue(this.invoices.length == 0 ? null : this.invoices[0].DocUrl);

            this.ewbForm.controls['XPCNId'].setValue(null);
            this.ewbForm.controls['InvoiceId'].setValue(null);
            this.ewbForm.controls['InvoiceNo'].setValue(null);
            this.ewbForm.controls['Date'].setValue(null);
            this.ewbForm.controls['ExpDate'].setValue(null);
            this.ewbForm.controls['InvoiceValue'].setValue(null);
            this.ewbForm.controls['DocUrl'].setValue(null);

            if (this.addEditForm.controls['InvValueBelow50k'].value == "false") {
              let date2 = null
              if(this.addEditForm.controls['EWBExpDate'].value != null){
                date2 = new Date(this._datePipe.transform(this.addEditForm.controls['EWBExpDate'].value, 'yyyy-MM-dd'));
                this.disableExpDate = false
              }
              else{
                this.disableExpDate = true
              }

              this.ewbForm.controls['XPCNId'].setValue(this.lclXPCNId);
              this.ewbForm.controls['InvoiceId'].setValue(this.waybills.length == 0 ? null : this.waybills[0].InvoiceId);
              this.ewbForm.controls['InvoiceNo'].setValue(this.addEditForm.controls['EWBNo'].value);
              this.ewbForm.controls['Date'].setValue(date1);
              this.ewbForm.controls['ExpDate'].setValue(date2);
              this.ewbForm.controls['InvoiceValue'].setValue(this.addEditForm.controls['InvAmt'].value);
              this.ewbForm.controls['DocUrl'].setValue(this.waybills.length == 0 ? null : this.waybills[0].DocUrl);
            }
          }

          this.addEditForm.controls['UsedStickerNo'].setValue(this.addEditForm.controls['NoOfPackages'].value);
          if (this.boxesChanged == true) {
            this.boxDetailsReal = [];
            this.boxDetails.forEach(x => {
              for (let a = x.Pkgs; a > 0; a--) {
                let box = {
                  BoxId: null,
                  XPCNId: this.lclXPCNId,
                  Length: x.Length,
                  Breadth: x.Breadth,
                  Height: x.Height,
                  Pkgs: 1,
                  Weight: Math.min(Math.round(Number(x.Weight / x.Pkgs)), 21.00),
                  isSelected: true,
                }

                this.boxDetailsReal.push(box);
              }
            });
          }

          // console.log(this.boxDetails);
          // console.log(this.boxDetailsReal);

          if (action == this.FINALIZE) {

            // if (this.addEditForm.controls['UsedStickerNo'].value == null || this.addEditForm.controls['UsedStickerNo'].value == "") {
            //   this.InputRequred = true;
            //   this.alertService.createAlert('Quantity of used sticker not suplied', 0)
            //   return;
            // }
            // if (this.addEditForm.controls['UsedStickerNo'].value != this.boxDetailsArr.length) {
            //   this.alertService.createAlert('Selected quantity is not matching with used quantity  (' + this.addEditForm.controls['UsedStickerNo'].value + '/' + this.boxDetailsArr.length + ')', 0)
            //   return;
            // }

            if (!this.gstinValidation()) {
              // this.alertService.createAlert("GSTIN not match with selected state ", 0)
              return;
            }
            // if (this.waybills.length <= 0) {
            //   this.alertService.createAlert("Eway-bill required ", 0)
            //   return;
            // }
            // if (this.invoices.length <= 0) {
            //   this.alertService.createAlert("invoices required ", 0)
            //   return;
            // }
            this.setValuesForAddEditFormControl([{ name: 'IsFinalized', value: true }]);
            // this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
            // let huNotGeneratedBoxes = this.boxDetails.filter(x => x.HUNumber == null).length
            // if (this.boxDetails.length == 0) {
            //   this.alertService.createAlert("Please Add Boxes", 0)
            //   return;
            // }
            // if (huNotGeneratedBoxes > 0) {
            //   this.alertService.createAlert("Please Save Boxes", 0)
            //   return;
            // }


          }
          else {
            this.setValuesForAddEditFormControl([{ name: 'IsFinalized', value: false }]);
            // let huNotGeneratedBoxes = this.boxDetails.filter(x => x.HUNumber == null).length
            // if (huNotGeneratedBoxes > 0) {
            //   this.alertService.createAlert("Please Save Boxes", 0)
            //   return;
            // }
            this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
          }
          // console.log(this.addEditForm.value, 'xpcn');

          this.btSaving = true;

          if (!this.invValLessThan50) {
            this.formData.delete('FormData');
            this.formData.append('FormData', JSON.stringify(this.ewbForm.value));
            this._pageService.saveLCLXPCNWaybill(this.formData).subscribe(data => {
              if (data != null) {
                if (data['Success'] != null) {
                  if (data['Success']) {

                  }
                }
              }
            });
          }

          this.formData.delete('FormData');
          this.formData.append('FormData', JSON.stringify(this.invForm.value));
          this._pageService.saveLCLXPCNInvoice(this.formData).subscribe(data => {
            if (data != null) {
              if (data['Success'] != null) {
                if (data['Success']) {
                  if (this.boxesChanged == true) {
                    this.boxesChanged = false;
                    this._pageService.upsertLCLOrderXPCNBoxesAll({ "LCLXPCNBoxes": this.boxDetailsReal }).subscribe(res => {
                      this.isSaveBoxesClicked = false
                      let responseStatus = res['Success']
                      // this.alertService.createAlert(res['Message'], responseStatus == true ? 1 : 0)
                      if (responseStatus) {
                        this._pageService.getLCLOrderXPCNBoxes(this.lclXPCNId).subscribe(res => {
                          this.boxDetailsReal = res['Data'];
                          this.boxDetailsReal.forEach(x => {
                            x.isSelected = true
                          });
                          this.addEditForm.controls['BoxesListConsignor'].setValue(this.boxDetails);
                          this.addEditForm.controls['BoxesList'].setValue(this.boxDetailsReal);
                          this.formData.delete('FormData');
                          this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
                          this._pageService.finalizeLCLXPCN(this.formData).subscribe(res => {
                            if (res['Success']) {

                              this.getXPCNDetailsForFinalization(true);
                              // this.getLCLXPCNBoxDetails();
                              this.isXPCNFinalized = action === this.FINALIZE ? true : false;
                              this.btSaving = false;
                              // this.alertService.createAlert(res['Message'], 1)
                            }
                            else {
                              // this.alertService.createAlert(res['Message'], 0)
                            }
                          })
                        })
                      }
                    });
                  } else {
                    this._pageService.getLCLOrderXPCNBoxes(this.lclXPCNId).subscribe(res => {
                      this.boxDetailsReal = res['Data'];
                      this.boxDetailsReal.forEach(x => {
                        x.isSelected = true
                      });
                      this.addEditForm.controls['BoxesListConsignor'].setValue(this.boxDetails);
                      this.addEditForm.controls['BoxesList'].setValue(this.boxDetailsReal);
                      this.formData.delete('FormData');
                      this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
                      this._pageService.finalizeLCLXPCN(this.formData).subscribe(res => {
                        if (res['Success']) {

                          this.getXPCNDetailsForFinalization(true);
                          // this.getLCLXPCNBoxDetails();
                          this.isXPCNFinalized = action === this.FINALIZE ? true : false;
                          this.btSaving = false;
                          // this.alertService.createAlert(res['Message'], 1)
                        }
                        else {
                          // this.alertService.createAlert(res['Message'], 0)
                        }
                      })
                    })
                  }
                }
              }
            }
          });
        }
        else {
          // console.log(this.addEditForm.value, 'xpcn');
          this.alertService.createAlert('Please fill all reqired details', 0)
          return;
        }
        // else {
        //   this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
        //   if (action == this.SAVE) {

        //     if (!this.invValLessThan50) {
        //       this.formData.delete('FormData');
        //       this.formData.append('FormData', JSON.stringify(this.ewbForm.value));
        //       this._pageService.saveLCLXPCNWaybill(this.formData).subscribe(data => {
        //         if (data != null) {
        //           if (data['Success'] != null) {
        //             if (data['Success']) {
        //             }
        //           }
        //         }
        //       });
        //     }

        //     this.formData.delete('FormData');
        //     this.formData.append('FormData', JSON.stringify(this.invForm.value));
        //     this._pageService.saveLCLXPCNInvoice(this.formData).subscribe(data => {
        //       if (data != null) {
        //         if (data['Success'] != null) {
        //           if (data['Success']) {
        //             this._pageService.upsertLCLOrderXPCNBoxes({ "LCLXPCNBoxes": this.boxDetails }).subscribe(res => {
        //               this.isSaveBoxesClicked = false
        //               let responseStatus = res['Success']
        //               // this.alertService.createAlert(res['Message'], responseStatus == true ? 1 : 0)
        //               if (responseStatus) {
        //                 this.formData.delete('FormData');
        //                 this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
        //                 this._pageService.finalizeLCLXPCN(this.formData).subscribe(res => {
        //                   if (res['Success']) {
        //                     this.getXPCNDetailsForFinalization(true);
        //                     // this.getLCLXPCNBoxDetails();
        //                     if (action == this.SAVE) this.ValidateEnterBoxes(this.METHOD_CALL);
        //                     // this.alertService.createAlert(res['Message'], 1)
        //                   }
        //                   else {
        //                     // this.alertService.createAlert(res['Message'], 0)
        //                   }
        //                 })
        //               }
        //             })
        //           }
        //         }
        //       }
        //     });

        //   }
        //   else {
        //     // console.log(this.addEditForm.value, 'xpcn');
        //     this.alertService.createAlert('Please fill all reqired details', 0)
        //     return;
        //   }
        // }
      }
      else {
        this.alertService.createAlert('XPCN already finalized', 0);
        return;
      }

    })
  }

  saveXPCN() {
    this._pageService.checkxpcnfinalizestatus(this.lclXPCNId).subscribe(res => {

      if (!res['Data']) {
        this.isFinalizeClicked = false
        this.setValuesForAddEditFormControl([{ name: 'XPCNId', value: this.lclXPCNId }])
        this.setValuesForAddEditFormControl([{ name: 'IsFinalized', value: false }])
        this.setValuesForAddEditFormControl([{ name: 'ConsignorName', value: this.getValueOfAddEditFormControl('NameOfConsignor') }])
        this.setValuesForAddEditFormControl([{ name: 'ConsigneeName', value: this.getValueOfAddEditFormControl('NameOfConsignee') }])

        let locationDetails =
          [
            { formControlId: 'ConsignorStateId', formControlName: 'ConsignorState', list: this.statesListConsignor, listKeyName: 'StateId', listKeyValue: 'StateName' },
            { formControlId: 'ConsigneeStateId', formControlName: 'ConsigneeState', list: this.statesListConsignee, listKeyName: 'StateId', listKeyValue: 'StateName' },
            { formControlId: 'ConsignorDistrictId', formControlName: 'ConsignorDistrict', list: this.districtsListConsignor, listKeyName: 'DistrictId', listKeyValue: 'DistrictName' },
            { formControlId: 'ConsigneeDistrictId', formControlName: 'ConsigneeDistrict', list: this.districtsListConsignee, listKeyName: 'DistrictId', listKeyValue: 'DistrictName' },
            { formControlId: 'ConsignorLocationId', formControlName: 'ConsignorLocation', list: this.locationsListConsignor, listKeyName: 'LocationId', listKeyValue: 'LocationName' },
            { formControlId: 'ConsigneeLocationId', formControlName: 'ConsigneeLocation', list: this.locationsListConsignee, listKeyName: 'LocationId', listKeyValue: 'LocationName' },
            // { formControlId: 'ConsignorPostcodeId', formControlName: 'ConsignorPostcode', list: this.postcodesListConsignor, listKeyName: 'PostcodeId', listKeyValue: 'PostcodeName' },
            // { formControlId: 'ConsigneePostcodeId', formControlName: 'ConsigneePostcode', list: this.postcodesListConsignee, listKeyName: 'PostcodeId', listKeyValue: 'PostcodeName' },
          ]

        locationDetails.forEach(data => {
          this.setFormControlNameWithId(data.formControlId, data.formControlName, data.list, data.listKeyName, data.listKeyValue)
        })

        this.formData.delete('FormData')

        if (this.isBreakBulk && this.getValueOfAddEditFormControl('Weight') != null && this.getValueOfAddEditFormControl('Weight') > this.availableWeight) {
          this.alertService.createAlert(`Weight is exceeding the payload.\n  Available Weight is ${this.availableWeight} Kgs`, 0)

          return;
        }

        let totalPkgs = 0;
        let chargedWeight = 0;
        let mainWeight = 0;

        
        if (this.boxDetails.length > 0) {
          this.boxDetails.forEach(x => {
            totalPkgs += Number(x.Pkgs);
            chargedWeight += Number(x.Weight);

            //for calculating total Charged Weight
            let individualMainWeight = Number(x.Weight) / Number(x.Pkgs);
            individualMainWeight = Math.min(individualMainWeight, 21.00);
            mainWeight += individualMainWeight * Number(x.Pkgs);
          });

          let actWeight = Number(this.addEditForm.controls['Weight'].value);

          this.addEditForm.controls['ChargedWeight'].setValue(mainWeight);

          if (totalPkgs != Number(this.addEditForm.controls['NoOfPackages'].value)) {
            this.alertService.createAlert("PKGS in Consignment Details must match sum of PKGS Docket Items", 0);
            return;
          }
        }


        let date1 = null
        if(this.addEditForm.controls['InvDate'].value != null){
          date1 = new Date(this._datePipe.transform(this.addEditForm.controls['InvDate'].value, 'yyyy-MM-dd'));
        }

        this.invForm.controls['XPCNId'].setValue(this.lclXPCNId);
        this.invForm.controls['InvoiceId'].setValue(this.invoices.length == 0 ? null : this.invoices[0].InvoiceId);
        this.invForm.controls['InvoiceNo'].setValue(this.addEditForm.controls['InvNo'].value);
        this.invForm.controls['Date'].setValue(date1);
        this.invForm.controls['InvoiceValue'].setValue(this.addEditForm.controls['InvAmt'].value);
        this.invForm.controls['DocUrl'].setValue(this.invoices.length == 0 ? null : this.invoices[0].DocUrl);

        this.ewbForm.controls['XPCNId'].setValue(null);
        this.ewbForm.controls['InvoiceId'].setValue(null);
        this.ewbForm.controls['InvoiceNo'].setValue(null);
        this.ewbForm.controls['Date'].setValue(null);
        this.ewbForm.controls['ExpDate'].setValue(null);
        this.ewbForm.controls['InvoiceValue'].setValue(null);
        this.ewbForm.controls['DocUrl'].setValue(null);

        if (this.addEditForm.controls['InvValueBelow50k'].value == "false") {

          let date2 = null
          if(this.addEditForm.controls['EWBExpDate'].value != null){
            date2 = new Date(this._datePipe.transform(this.addEditForm.controls['EWBExpDate'].value, 'yyyy-MM-dd'));
            this.disableExpDate = false
          }
          else{
            this.disableExpDate = true
          }

          this.ewbForm.controls['XPCNId'].setValue(this.lclXPCNId);
          this.ewbForm.controls['InvoiceId'].setValue(this.waybills.length == 0 ? null : this.waybills[0].InvoiceId);
          this.ewbForm.controls['InvoiceNo'].setValue(this.addEditForm.controls['EWBNo'].value);
          this.ewbForm.controls['Date'].setValue(date1);
          this.ewbForm.controls['ExpDate'].setValue(date2);
          this.ewbForm.controls['InvoiceValue'].setValue(this.addEditForm.controls['InvAmt'].value);
          this.ewbForm.controls['DocUrl'].setValue(this.waybills.length == 0 ? null : this.waybills[0].DocUrl);
        }

        this.addEditForm.controls['UsedStickerNo'].setValue(this.addEditForm.controls['NoOfPackages'].value);
        if (this.boxesChanged == true) {
          this.boxDetailsReal = [];
          this.boxDetails.forEach(x => {
            for (let a = x.Pkgs; a > 0; a--) {
              let box = {
                BoxId: null,
                XPCNId: this.lclXPCNId,
                Length: x.Length,
                Breadth: x.Breadth,
                Height: x.Height,
                Pkgs: 1,
                Weight: Math.min(Math.round(Number(x.Weight / x.Pkgs)), 21.00),
                isSelected: true,
              }

              this.boxDetailsReal.push(box);
              console.log(this.boxDetails);
            }
          });
        }

        else {
          this.setValuesForAddEditFormControl([{ name: 'IsFinalized', value: false }]);

          this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
        }

        this.btSaving = true;
debugger
        if (!this.invValLessThan50 && (this.ewbForm.controls['InvoiceNo'].value != null && this.ewbForm.controls['InvoiceNo'].value != '')) {
          this.formData.delete('FormData');
          this.formData.append('FormData', JSON.stringify(this.ewbForm.value));
          this._pageService.saveLCLXPCNWaybill(this.formData).subscribe(data => {
            if (data != null) {
              if (data['Success'] != null) {
                if (data['Success']) {

                }
              }
            }
          });
        }

        this.formData.delete('FormData');
        this.formData.append('FormData', JSON.stringify(this.invForm.value));
        this._pageService.saveLCLXPCNInvoice(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                if (this.boxesChanged == true) {
                  this.boxesChanged = false;
                  this._pageService.upsertLCLOrderXPCNBoxesAll({ "LCLXPCNBoxes": this.boxDetailsReal }).subscribe(res => {
                    this.isSaveBoxesClicked = false
                    let responseStatus = res['Success']
                    // this.alertService.createAlert(res['Message'], responseStatus == true ? 1 : 0)
                    if (responseStatus) {
                      this._pageService.getLCLOrderXPCNBoxes(this.lclXPCNId).subscribe(res => {
                        this.boxDetailsReal = res['Data'];
                        this.boxDetailsReal.forEach(x => {
                          x.isSelected = true
                        });
                        this.addEditForm.controls['BoxesListConsignor'].setValue(this.boxDetails);
                        this.addEditForm.controls['BoxesList'].setValue(this.boxDetailsReal);
                        this.formData.delete('FormData');
                        this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
                        this._pageService.finalizeLCLXPCN(this.formData).subscribe(res => {
                          if (res['Success']) {

                            this.getXPCNDetailsForFinalization(true);
                            // this.getLCLXPCNBoxDetails();
                            this.isXPCNFinalized = false;
                            this.btSaving = false;
                            // this.alertService.createAlert(res['Message'], 1)
                          }
                          else {
                            // this.alertService.createAlert(res['Message'], 0)
                          }
                        })
                      })
                    }
                  });
                } else {
                  this._pageService.getLCLOrderXPCNBoxes(this.lclXPCNId).subscribe(res => {
                    this.boxDetailsReal = res['Data'];
                    this.boxDetailsReal.forEach(x => {
                      x.isSelected = true
                    });
                    this.addEditForm.controls['BoxesListConsignor'].setValue(this.boxDetails);
                    this.addEditForm.controls['BoxesList'].setValue(this.boxDetailsReal);
                    this.formData.delete('FormData');
                    this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
                    this._pageService.finalizeLCLXPCN(this.formData).subscribe(res => {
                      if (res['Success']) {

                        this.getXPCNDetailsForFinalization(true);
                        // this.getLCLXPCNBoxDetails();
                        this.isXPCNFinalized = false;
                        this.btSaving = false;
                        // this.alertService.createAlert(res['Message'], 1)
                      }
                      else {
                        // this.alertService.createAlert(res['Message'], 0)
                      }
                    })
                  })
                }
              }
            }
          }
        });

      }
      else {
        this.alertService.createAlert('XPCN already finalized', 0);
        return;
      }

    })
  }

  gstinValidation(): boolean {
    let consingorGTSINF = this.getValueOfAddEditFormControl("ConsignorGSTIN");
    let consigneeGSTINF = this.getValueOfAddEditFormControl("ConsigneeGSTIN")

    if (consingorGTSINF.length > 2 && this.getValueOfAddEditFormControl("ConsignorStateId") > 0
      && this.statesListConsignor.filter(x => x.StateId == this.getValueOfAddEditFormControl("ConsignorStateId")).length > 0) {
      let consignorGSTIN = this.statesListConsignor.filter(x => x.StateId == this.getValueOfAddEditFormControl("ConsignorStateId"))[0]["StateGSTIN"]
      if ((consingorGTSINF.substring(0, 2) != consignorGSTIN)) {
        this.addEditForm.controls['ConsignorGSTIN'].markAllAsTouched()
        this.alertService.createAlert("Invalid Consingor GSTIN Number", 0);
        return false;
      }
    }
    else if (consingorGTSINF.length > 0 && consingorGTSINF.length != 15) {
      this.alertService.createAlert("Consingnor GSTIN Should be of 15 Digits", 0);
      return false;
    }

    if (consigneeGSTINF.length > 2 && this.getValueOfAddEditFormControl("ConsigneeStateId") > 0
      && this.statesListConsignee.filter(x => x.StateId == this.getValueOfAddEditFormControl("ConsigneeStateId")).length > 0) {
      let consigneeGSTIN = this.statesListConsignee.filter(x => x.StateId == this.getValueOfAddEditFormControl("ConsigneeStateId"))[0]["StateGSTIN"]
      if ((consigneeGSTINF.substring(0, 2) != consigneeGSTIN)) {
        this.addEditForm.controls['ConsigneeGSTIN'].markAllAsTouched()
        this.alertService.createAlert("Invalid Consingnee GSTIN Number", 0);
        return false;
      }
    }
    else if (consigneeGSTINF.length > 0 && consigneeGSTINF.length != 15) {
      this.alertService.createAlert("Consingnee GSTIN Should be of 15 Digits", 0);
      return false;
    }
    return true;
  }





  postcodeChanged(event) {
  }

  setFormControlNameWithId(formControlId: string, formControlName: string, list: any[], listKeyName: string, listKeyValue: string) {
    this.setValuesForAddEditFormControl([{ name: formControlName, value: list.length > 0 ? (list.filter(x => x[listKeyName] == this.getValueOfAddEditFormControl(formControlId)).length > 0 ? list.filter(x => x[listKeyName] == this.getValueOfAddEditFormControl(formControlId))[0][listKeyValue] : null) : null }])
  }



  public onlyNumbers(event) {
    let k;
    k = event.charCode;
    return ((k > 47 && k < 58) || k === 46); // Allow digits 0-9 and decimal point
  }

  static onlyAlphabetsWithSpace(event) {
    let k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 32);
  }




  setLocationDetails(data: any[]) {

    this.getDistricts(this.getValueOfAddEditFormControl('ConsignorStateId'), this.CONSIGNOR)
    this.setValuesForAddEditFormControl([{ name: "ConsignorDistrictId", value: data['ConsignorDistrictId'] }])
    this.getDistricts(this.getValueOfAddEditFormControl('ConsigneeStateId'), this.CONSIGNEE)
    this.setValuesForAddEditFormControl([{ name: "ConsigneeDistrictId", value: data['ConsigneeDistrictId'] }])

    this.getLocations(this.getValueOfAddEditFormControl('ConsignorDistrictId'), this.CONSIGNOR)
    this.setValuesForAddEditFormControl([{ name: "ConsignorLocationId", value: data['ConsignorLocationId'] }])
    this.getLocations(this.getValueOfAddEditFormControl('ConsigneeDistrictId'), this.CONSIGNEE)
    this.setValuesForAddEditFormControl([{ name: "ConsigneeLocationId", value: data['ConsigneeLocationId'] }])

    this.getPostCodes(this.getValueOfAddEditFormControl('ConsignorLocationId'), this.CONSIGNOR)
    this.setValuesForAddEditFormControl([{ name: "ConsignorPostcodeId", value: data['ConsignorPostcodeId'] }])
    this.setValuesForAddEditFormControl([{ name: "ConsignorPostcode", value: data['ConsignorPostcode'] }])
    this.getPostCodes(this.getValueOfAddEditFormControl('ConsigneeLocationId'), this.CONSIGNEE)
    this.setValuesForAddEditFormControl([{ name: "ConsigneePostcodeId", value: data['ConsigneePostcodeId'] }])
    this.setValuesForAddEditFormControl([{ name: "ConsigneePostcode", value: data['ConsigneePostcode'] }])

  }

  calculateBoxWeight(i: number) {
    this.duplicateDimensionError = false;

    if (this.boxDetails[i].Length != null &&
        this.boxDetails[i].Breadth != null &&
        this.boxDetails[i].Height != null &&
        this.boxDetails[i].Pkgs != null) {

      // Check for duplicate dimensions
      this.checkDuplicateDimensions(this.boxDetails[i], i);

      if (this.duplicateDimensionError) {
        this.alertService.createAlert("Duplicate dimensions detected", 0);
        this.deleteBox(i);
        return;
      }

      // Box Volumetric Weight Calculation
      let weight = 6 * Number(this.boxDetails[i].Length) * Number(this.boxDetails[i].Breadth) * Number(this.boxDetails[i].Height);
      weight = weight / 27000;
      if (weight > 21.00) {
        weight = 21 * Number(this.boxDetails[i].Pkgs);
      } else {
        weight = weight * Number(this.boxDetails[i].Pkgs);
      }
      this.boxDetails[i].Weight = weight.toFixed(2);

      // Update total packages and weight
      this.calculateTotalPkgsWt();
    }
  }

  checkDuplicateDimensions(item, index) {
    for (let i = 0; i < this.boxDetails.length; i++) {
      if (i !== index) { // Skip the current row
        const box = this.boxDetails[i];
        if (box.Length === Number(item.Length) && box.Breadth === Number(item.Breadth) && box.Height === Number(item.Height)) {
          this.duplicateDimensionError = true;
          return;
        }
      }
    }
  }

  calculateTotalPkgsWt() {
    let mainWeight = 0;
    this.totalPkgs = 0;
    this.totalVWt = 0;
    this.boxDetails.forEach(x => {
      this.totalPkgs += Number(x.Pkgs);
      this.totalVWt += Number(x.Weight);

      //for calculating total Vol Weight
      let individualMainWeight = Number(x.Weight) / Number(x.Pkgs);
      individualMainWeight = Math.min(individualMainWeight, 21.00);
      mainWeight += individualMainWeight * Number(x.Pkgs);
    });
    this.totalVWt = Number(mainWeight.toFixed(2));
    this.addEditForm.controls['NoOfPackages'].setValue(this.totalPkgs);
  }

  addBox(boxes, mode: number) {
    this.boxesChanged = true;
    if (mode == this.MULTIPLE) {
      this.boxDetails = []
    }


    if (boxes > 0 && typeof (boxes) != "undefined") {
      for (let i = 1; i <= boxes; i++) {
        if (mode == this.SINGLE) this.noOfBoxesAdded++;
        this.boxDetails.push({ BoxId: null, XPCNId: this.lclXPCNId, Length: 0, Breadth: 0, Height: 0, Pkgs: 1, Weight: 0, HUNumber: null, HUBarcodeUrl: null })
      }
    }
    this.calculateTotalPkgsWt();
  }

  deleteBox(index) {
    this.boxesChanged = true;
    if (this.boxDetails[index].BoxId != null) {
      this._pageService.deleteXPCNBoxesConsignor(this.boxDetails[index].BoxId).subscribe(data => {

      });
    }
    this.boxDetails.splice(index, 1);
    this.noOfBoxesAdded -= 1;
    this.calculateTotalPkgsWt();
  }

  // saveBoxes() {
  //   this.isSaveBoxesClicked = true
  //   if (this.boxDetails.length > 0) {
  //     this._pageService.upsertLCLOrderXPCNBoxes({ "LCLXPCNBoxes": this.boxDetails }).subscribe(res => {
  //       this.isSaveBoxesClicked = false
  //       let responseStatus = res['Success']
  //       this.alertService.createAlert(res['Message'], responseStatus == true ? 1 : 0)
  //       if (responseStatus) {
  //         this.getLCLXPCNBoxDetails()
  //       }
  //     })
  //   }
  //   else {
  //     this.alertService.createAlert("Please add box details", 0)
  //     this.isSaveBoxesClicked = false
  //   }
  // }

  inputBox(event) {
    this.isValueEntered = true
  }

  invValChanged(checked) {
    if (checked == "true") {
      this.invValLessThan50 = true;
      // if(this.invoices[0].InvoiceValue == null){
      // this.addEditForm.controls['EWBNo'].setValue(null);
      // this.addEditForm.controls['EWBExpDate'].setValue(null);
      // this.addEditForm.controls['InvNo'].setValue(null);
      // this.addEditForm.controls['InvDate'].setValue(null);
      // this.addEditForm.controls['InvAmt'].setValue(null);
      // this.invTotal = 0;
      // }
    } else {
      this.invValLessThan50 = false;
    }
  }

  getLCLXPCNBoxDetails() {
    this._pageService.getLCLOrderXPCNBoxes(this.lclXPCNId).subscribe(res => {
      this.boxDetailsReal = res['Data'];
      // if (this.boxDetails.length == 0 && this.addEditForm.controls['NoOfPackages'].value != null && this.addEditForm.controls['NoOfPackages'].value != '') {
      //   this.boxDetails.push({
      //     BoxId: null,
      //     XPCNId: this.lclXPCNId,
      //     Length: 0,
      //     Breadth: 0,
      //     Height: 0,
      //     Pkgs: Number(this.addEditForm.controls['NoOfPackages'].value),
      //     Weight: 0,
      //     HUNumber: null,
      //     HUBarcodeUrl: null
      //   });
      //   // this.addBox(Number(this.addEditForm.controls['NoOfPackages'].value), this.SINGLE);
      //   // this.boxDetails.forEach(x => {
      //   //   // x.Pkgs = 1,
      //   //     x.Weight = Number(x.Length) * Number(x.Breadth) * Number(x.Height) * Number(x.Pkgs);
      //   // });
      // }
      //console.log(this.boxDetails, "this.boxDetails--")
      // this.boxesFromBackend = this.boxDetails.length
      // this.noOfBoxesAdded = this.boxDetails.length
      // //this.setValuesForAddEditFormControl([{ name: "UsedStickerNo", value: null }]);
      // this.boxDetailsArr = this.boxDetails.filter(x => x.isSelected === true);
      // this.addEditForm.controls['BoxesList'].setValue(this.boxDetails);
      // const count = this.boxDetails.filter(item => item.isSelected === true).length;
      // if (count > 0) { this.setValuesForAddEditFormControl([{ name: "UsedStickerNo", value: count }]); }
      // else { this.setValuesForAddEditFormControl([{ name: "UsedStickerNo", value: null }]); }
      // this.ValidateEnterBoxes(this.FROM_INPUT);

    })
  }

  onNavigate(value) {
    window.open("https://xpindiadocuments.s3.ap-south-1.amazonaws.com/" + value.HUBarcodeUrl, "_blank");
  }


  VACSelectionChange(event) {
    this.addEditForm.controls['isVAC'].setValue(event)
    this.isValueAddedCharges = event
    if (event) {
      this.setAddEditFormValidator("VACRemarkId");

    }
    else {
      this.clearAddEditFormValidator("VACRemarkId", true);
    }

    this.resetVACValues()

  }

  VACRemarkSelectionChange(event) {
    let selectedRemark = this.vacRemarksList.filter(x => x.id == event.value)[0]
    this.setValuesForAddEditFormControl([{ name: "VACRemarkName", value: selectedRemark.name }])
    if (selectedRemark.name === 'COD' || selectedRemark.name === 'DOD') {
      this.isCODDODCheckBoxEnabled = true
    }
    else {
      this.isCODDODCheckBoxEnabled = false
    }

    this.isCODORDODChecked = true
    this.setValuesForAddEditFormControl([{ name: "ValueAddedCharge", value: selectedRemark.amount }, { name: "CODBODChecked", value: this.isCODORDODChecked }])
    this.clearAddEditFormValidator("AmountToCollect", true)
    this.calculateTotal(this.rate, selectedRemark.amount, this.gst)

  }

  isBOD_OR_DODChecked(event) {
    this.isCODORDODChecked = event.checked
    if (!this.isCODORDODChecked) this.setAddEditFormValidator("AmountToCollect")
    else {
      this.clearAddEditFormValidator("AmountToCollect", true)
    }
    this.setValuesForAddEditFormControl([{ name: "CODBODChecked", value: event.checked }])
  }

  resetVACValues() {
    this.calculateTotal(0)
    this.isCODDODCheckBoxEnabled = false
    this.isCODORDODChecked = true
    this.setValuesForAddEditFormControl([{ name: "CODBODChecked", value: this.isCODORDODChecked }, { name: "VACRemarkId", value: null }, { name: "VACRemarkName", value: null }, { name: "ValueAddedCharge", value: "" }])


  }

  setVACIfExists(data) {
    this.addEditForm.controls['isVAC'].setValue(data['isVAC'])
    this.isValueAddedCharges = this.addEditForm.controls['isVAC'].value
    if (this.isValueAddedCharges) { //If VAC Charges are Yes

      this.addEditForm.controls['VACRemarkId'].setValue(data['VACRemarkId'])
      this.addEditForm.controls['VACRemarkName'].setValue(data['VACRemarkName'])
      this.addEditForm.controls['ValueAddedCharge'].setValue(data['ValueAddedCharge'])
      //If VACRemarkId  is  null it means the VAC reason is  COD or DOD and vice-versa
      if (this.addEditForm.controls['VACRemarkId'].value == null) {
        let vacRemarkName = this.addEditForm.controls['VACRemarkName'].value
        if (vacRemarkName == 'COD' || vacRemarkName == 'DOD') {
          this.isCODDODCheckBoxEnabled = true //As it is COD or DOD, showing check box to the user
          if (vacRemarkName === 'COD') { this.addEditForm.controls['VACRemarkId'].setValue(-3) }
          else if (vacRemarkName === 'DOD') { this.addEditForm.controls['VACRemarkId'].setValue(-4) }

          let amountToCollect = data['AmountToCollect']
          if (amountToCollect == null) {
            this.isCODORDODChecked = true //As AmountToCollect is null, it means checkbox is checked and no need to show input box to the user
            this.addEditForm.controls['CODBODChecked'].setValue(this.isCODORDODChecked)
          }
          else {
            //As AmountToCollect is not null, it means checkbox is not checked and user has entered some value in vacAmount input box
            this.isCODORDODChecked = false
            this.addEditForm.controls['CODBODChecked'].setValue(this.isCODORDODChecked)
            this.addEditForm.controls['AmountToCollect'].setValue(amountToCollect)
          }
        }
      }




    }
  }

  openLCLXPCNPreview() {
    this._pageService.getLCLXPCNDetailsForFinzalization(this.lclXPCNId).subscribe(data => {
      if (data != null && data['Data'] != null) {

        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;

        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;

        // window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); 
        // this._router.navigate([]).then(result => {  window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); });
        let dialogRef = this.dialog.open(PreviewXpcnComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }


  ConsigneeDetails: any[] = [];
  ConsigneeDetailsFilt: any[] = [];
  GetAllConsignee() {
    this._pageService.getConsigneeDetails().subscribe(data => {
      if (data['Success']) {
        // console.log(data);
        this.ConsigneeDetails = data['Data'];
        this.ConsigneeDetailsFilt = data['Data'];
      }
    })
  }

  FilterConsigneeDetails(value) {
    if (value === '') {
      this.ConsigneeDetailsFilt = this.ConsigneeDetails;
      return
    }
    const filterValue = value.toLowerCase();
    this.ConsigneeDetailsFilt = this.ConsigneeDetails.filter(option => option.LookupName != null && option.LookupName != '' && option.LookupName.toLowerCase().includes(filterValue));
  }

  FilterConsignee(value) {
    this._pageService.getConsigneeDetailsbyId(value).subscribe(data => {
      if (data['Data'] != null) {
        this.setValuesForAddEditFormControl(
          [{ name: "ConsigneeMobile", value: data['Data'][0].Mobile },
          { name: "ConsigneeEmail", value: data['Data'][0].Email },
          { name: "ConsigneeStateId", value: data['Data'][0].StateId },
          { name: "ConsigneeDistrictId", value: data['Data'][0].DistrictId },
          { name: "ConsigneeLocationId", value: data['Data'][0].LocationId },
          { name: "ConsigneePostcodeId", value: data['Data'][0].PostcodeId },
          { name: "ConsigneePostcode", value: data['Data'][0].Postcode },
          { name: "ConsigneeGSTIN", value: data['Data'][0].GSTIN },
          { name: "ConsigneeAddress", value: data['Data'][0].Address },
          { name: "ConsigneeName", value: data['Data'][0].ConsigneeName },
          { name: "NameOfConsignee", value: data['Data'][0].ConsigneeName }
          ])

        this.getDistricts(data['Data'][0].StateId, this.CONSIGNEE);
        this.CheckGatewayAvailabiity(data['Data'][0].DistrictId, this.CONSIGNEE, this.lclXPCNId)
        this.getPostCodes(data['Data'][0].LocationId, this.CONSIGNEE);
      }

    })
  }

  CheckGatewayAvailabiity(DistrictId: any, Type: any, xpcnId: any) {
    this._pageService.CheckGatewayForDistrict(DistrictId, xpcnId).subscribe(res => {
      if (res['Success']) {
        this.getLocations(DistrictId, Type);
      }
    })
  }

  EnteredUsedSticker: number;
  FROM_INPUT: number = 1;
  METHOD_CALL: number = 2;
  ValidateEnterBoxes(actionType: number) {

    this.EnteredUsedSticker = this.getValueOfAddEditFormControl("UsedStickerNo");
    if (this.helperBoxDetails.length == 0 || actionType == this.FROM_INPUT) {
      this.helperBoxDetails = this.boxDetails
      if (this.EnteredUsedSticker > this.noOfBoxesAdded) {
        this.alertService.createAlert('Entered sticke Quantity is greater than stickers,Please eneter correct input', 0);
        this.setValuesForAddEditFormControl([{ name: 'UsedStickerNo', value: null }])
        this.InputRequred = true;
      }
      else {
        this.InputRequred = false;
        this.setValuesForAddEditFormControl([{ name: 'UsedStickerNo', value: this.EnteredUsedSticker }]);

        for (var i = 0; i < this.boxDetails.length; i++) {
          if (i < this.addEditForm.controls['UsedStickerNo'].value) {
            this.boxDetails[i].isSelected = true;
          }
          else {
            this.boxDetails[i].isSelected = false;
          }
        }
        this.boxDetailsArr = this.boxDetails.filter(x => x.isSelected);
        this.isBoxesCheckBox = true;
        this.addEditForm.controls['BoxesList'].setValue(this.boxDetails);
      }
    }
    else {
      this.boxDetails = this.helperBoxDetails;
    }



    //console.log(this.addEditForm.controls['BoxesList'].value);
  }

  boxDetailsArr: any[] = [];
  checkboxChanged(e) {
    this.isBoxesCheckBox = false;
    this.boxDetailsArr = this.boxDetails.filter(x => x.isSelected);
    if (this.addEditForm.controls['UsedStickerNo'].value == this.boxDetailsArr.length) {
      this.isBoxesCheckBox = true;
    }
    else {
      this.isBoxesCheckBox = false;
    }
    this.addEditForm.controls['BoxesList'].setValue(this.boxDetails);
    //console.log(this.addEditForm.controls['BoxesList'].value);
  }

  deleteItem() {
    this.items.pop();
  }

  addItem() {
    let item =
    {
      l: '',
      b: '',
      h: '',
      p: '',
      w: '',
    }
    this.items.push(item);
  }

  invAmtChanged() {
    if (this.addEditForm.controls['InvAmt'].value != null && this.addEditForm.controls['InvAmt'].value != '' && this.addEditForm.controls['InvAmt'].value != undefined) {
      this.invTotal = Number(this.addEditForm.controls['InvAmt'].value);
      this.invTotal = this.addEditForm.controls['InvAmt'].value;
      // const numWords = require('num-words');   
      //  this.amtInWords = numWords(this.invTotal);
    }
  }

}

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'numberToWords'
})
export class NumberToWordsPipe implements PipeTransform {
  transform(value: any): string {
    if (value && isInteger(value))
      return numToWords(value);

    return value;
  }
}

const isInteger = function (x: any) {
  return x % 1 === 0;
}


const arr = x => Array.from(x);
const num = x => Number(x) || 0;
const str = x => String(x);
const isEmpty = xs => xs.length === 0;
const take = n => xs => xs.slice(0, n);
const drop = n => xs => xs.slice(n);
const reverse = xs => xs.slice(0).reverse();
const comp = f => g => x => f(g(x));
const not = x => !x;
const chunk = n => xs =>
  isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];
// numToWords :: (Number a, String a) => a -> String
let numToWords = n => {
  let a = [
    '', 'one', 'two', 'three', 'four',
    'five', 'six', 'seven', 'eight', 'nine',
    'ten', 'eleven', 'twelve', 'thirteen', 'fourteen',
    'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
  ];
  let b = [
    '', '', 'twenty', 'thirty', 'forty',
    'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
  ];
  let g = [
    '', 'thousand', 'million', 'billion', 'trillion', 'quadrillion',
    'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion'
  ];
  // this part is really nasty still
  // it might edit this again later to show how Monoids could fix this up
  let makeGroup = ([ones, tens, huns]) => {
    return [
      num(huns) === 0 ? '' : a[huns] + ' hundred ',
      num(ones) === 0 ? b[tens] : b[tens] && b[tens] + '-' || '',
      a[tens + ones] || a[ones]
    ].join('');
  };
  // "thousands" constructor; no real good names for this, i guess
  let thousand = (group, i) => group === '' ? group : `${group} ${g[i]}`;
  // execute !
  if (typeof n === 'number') return numToWords(String(n));
  if (n === '0') return 'zero';
  return comp(chunk(3))(reverse)(arr(n))
    .map(makeGroup)
    .map(thousand)
    .filter(comp(not)(isEmpty))
    .reverse()
    .join(' ');
};