import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-demo-video-language',
  templateUrl: './demo-video-language.component.html',
  styleUrls: ['./demo-video-language.component.scss']
})
export class DemoVideoLanguageComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DemoVideoLanguageComponent>
    ) { 
      dialogRef.disableClose = true;
    }

  ngOnInit(): void {
  }
  
  close(isEnglish): void {
    this.dialogRef.close(isEnglish);
  }
  closewindow(){
    this.dialogRef.close();
  }
}
