<div class="newMarginClass">
    <i class="fa fa-times" aria-hidden="true" (click)="close()"
        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;cursor: pointer;"></i>
    <h3 mat-dialog-title>MAP <span>({{VehicleNumber}})/({{OrderId}})</span></h3>
</div>

<!-- <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
    <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="false"></agm-marker>
</agm-map> -->

<div mat-dialog-content class="p-0 m-0">

    <agm-map (mapReady)="onMapReady($event)" [zoom]="16" [latitude]="lat" [longitude]="lng">
        <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
        <agm-marker [latitude]="lat1" [longitude]="lng1"></agm-marker>
        <agm-marker [latitude]="lat2" [longitude]="lng2"></agm-marker>
    </agm-map>
</div>
<!-- <div class="float-right mt-2">
    <button class="btn btn-primary float-right" (click)="close()">Cancel</button>
</div> -->
<mat-dialog-actions align="end" style="padding-bottom: 10px ;margin-right: 12px;">
    <button class="btn btn-danger fa fa-times-circle" (click)="close()"
        style="margin-left: 10px ; background-color: #f44336 !important;">
        <span style="font-family: Arial, Helvetica, sans-serif;">
            Close</span></button>
</mat-dialog-actions>