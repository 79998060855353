<div class="NewHeader">
    <i class="fa fa-times" aria-hidden="true"
        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;" (click)="close()"></i>
    <h1 mat-dialog-title>Upload CSV</h1>
</div>
<div mat-dialog-content>
    <form class="form-horizontal" novalidate [formGroup]="addEditForm">
        <div class="form-group" style="margin: 15px !important;">
            <label class="col-lg-4 col-md-4 col-sm-4 col-xs-4 add-member-label">Upload Document: </label>
            <input type="hidden" formControlName="Type">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 margins">
                <input type="file" accept="text/csv" placeholder="Upload file..." formControlName="Doc"
                    (change)="handleFileInput($event.target.files)" style="margin-top:-3px!important; cursor: pointer;"
                    required>
                <div *ngIf="!addEditForm.controls.Doc.valid && (addEditForm.controls.Doc.dirty || addEditForm.controls.Doc.touched)"
                    class="error-text">
                    <div *ngIf="addEditForm.controls.Doc.errors.required">
                        Document Upload is required
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<mat-dialog-actions align="end" style="padding-bottom:10px">
    <button *ngIf="showDownloadCSV == true && (type == 'Book New Order' || type == 'Backdated Orders' || type == 'Consignee')"  class="btn pull-right m-2 fa fa-download"
        style="cursor:pointer;left:6px;background-color: #286ba5; color: white;" (click)="downloadCSV()">
        <span> Download CSV</span>
    </button>
    <button *ngIf="isSubmitting == false" 
        style="cursor:pointer;left:6px;background-color: #286ba5; color: white;" (click)="saveData()" class="btn pull-right m-2 fa fa-upload">
        <span> Upload</span>
    </button>
    <button *ngIf="isSubmitting == true"  class="btn-primary pull-right btn-disabled btn m-2 fa fa-upload">
        <span> Uploading..</span>
    </button>
    <button   style="color: white !important;left:6px"
        class="btn pull-right m-2 fa fa-upload btn-danger" (click)="close()">
        &nbsp;Cancel
    </button>
</mat-dialog-actions>