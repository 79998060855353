import { Menu } from './menu.model';

export const verticalMenuItems = [
    // new Menu (1, 'Address Book', '/xp/address-book', null, 'address', null, false, 0),
    new Menu (2, 'ASN List', '/xp/asn-list', null, 'shopping-cart', null, false, 0),
    new Menu (3, 'Print Stickers','/xp/print-lr-tracking', null, 'print', null, false, 0),
    new Menu (5, 'Print and Track Your XPCN','/xp/track-lr', null, 'gears', null, false, 0),
    new Menu (6, 'Delivered','/xp/delivered', null, 'truck', null, false, 0),
    new Menu (9, 'Cancelled ASNs','/xp/cancelled-asn', null, 'ban', null, false, 0),
    new Menu (8, 'Demo Video','/xp/demo-video', null, 'play', null, false, 0),
    // new Menu (4, 'Logout', '/login', null, 'users', null, false, 0),
    // new Menu (5, 'Service/Performance', '/xp/service', null, 'truck', null, false, 0),
    // new Menu (6, 'Payables', '/xp/payables', null, 'rupee', null, false, 0),
    // new Menu (7, 'Chat With XP', '/xp/chat', null, 'comment', null, false, 0)
]

export const customerMenuItems = [
    // new Menu (1, 'Address Book', '/xp/address-book', null, 'address', null, false, 0),
    new Menu (2, 'ASN List', '/xp/asn-list', null, 'shopping-cart', null, false, 0),
    new Menu (3, 'Print Stickers','/xp/print-lr-tracking', null, 'print', null, false, 0),
    new Menu (5, 'Print and Track Your XPCN','/xp/track-lr', null, 'gears', null, false, 0),
    new Menu (6, 'Delivered','/xp/delivered', null, 'truck', null, false, 0),
    new Menu (9, 'Cancelled ASNs','/xp/cancelled-asn', null, 'ban', null, false, 0),
    new Menu (7, 'Download MIS','/xp/download-mis', null, 'bar-chart', null, false, 0),
    // new Menu (10, 'Invoices & PODs','/xp/finance', null, 'inr', null, false, 0),
    new Menu (8, 'Demo Video','/xp/demo-video', null, 'play', null, false, 0),
]

export const horizontalMenuItems = [ 
    //new Menu (1, 'Dashboard', '/xp/analytics', null, 'bar-chart', null, false, 0),
    // new Menu (2, 'My Orders', '/xp/orders', null, 'shopping-cart', null, false, 0),
    // new Menu (3, 'My Utilization', '/xp/utilization', null, 'gears', null, false, 0),
    // new Menu (4, 'My Info', '/xp/dashboard', null, 'users', null, false, 0),
    // new Menu (5, 'Service/Performance', '/xp/service', null, 'truck', null, false, 0),
    // new Menu (6, 'Payables', '/xp/payables', null, 'rupee', null, false, 0),
    // new Menu (7, 'Chat With XP', '/xp/chat', null, 'comment', null, false, 0)
]
